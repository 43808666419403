/**
 * @generated SignedSource<<e454109669c81f5e28d31c1c1ada2eb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppContainer_learner$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppSidebar_learner" | "NewAchievementDialogFragment">;
  readonly " $fragmentType": "AppContainer_learner";
};
export type AppContainer_learner$key = {
  readonly " $data"?: AppContainer_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppContainer_learner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppContainer_learner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppSidebar_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewAchievementDialogFragment"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "dd144ffbabc6c920c92b1fd2f757e726";

export default node;
