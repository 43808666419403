/**
 * @generated SignedSource<<39044afbc643fba80f1f96d45d4a3e0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ChooseInitiativeMutation$variables = {
  initiative: string;
};
export type ChooseInitiativeMutation$data = {
  readonly chooseInitiative: {
    readonly currentInitiative: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly pastInitiatives: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  };
};
export type ChooseInitiativeMutation = {
  response: ChooseInitiativeMutation$data;
  variables: ChooseInitiativeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiative"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "initiative",
    "variableName": "initiative"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Initiative",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": "currentInitiative",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "atDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
},
v5 = {
  "alias": "pastInitiatives",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "beforeDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "initiatives(membershipFilter:{\"beforeDate\":0})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChooseInitiativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "chooseInitiative",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChooseInitiativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "chooseInitiative",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b28932b8c07e1946fdf9c5ce96526b4",
    "id": null,
    "metadata": {},
    "name": "ChooseInitiativeMutation",
    "operationKind": "mutation",
    "text": "mutation ChooseInitiativeMutation(\n  $initiative: ID!\n) {\n  chooseInitiative(initiative: $initiative) {\n    currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n      nodes {\n        id\n      }\n    }\n    pastInitiatives: initiatives(membershipFilter: {beforeDate: 0}) {\n      nodes {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3aa08df747a2b6d8262358d52770a097";

export default node;
