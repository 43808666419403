/**
 * @generated SignedSource<<c6fb6543bf3d7fef92fa8aaf6d24832a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesCardLearnerFragment$data = {
  readonly learnerProfile: {
    readonly practiceScores: ReadonlyArray<{
      readonly managerScore: number | null;
      readonly practice: {
        readonly id: string;
        readonly name: string;
      };
      readonly score: number | null;
    }>;
  };
  readonly practice: {
    readonly practice: {
      readonly id: string;
    };
  } | null;
  readonly " $fragmentType": "PracticesCardLearnerFragment";
};
export type PracticesCardLearnerFragment$key = {
  readonly " $data"?: PracticesCardLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PracticesCardLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PracticesCardLearnerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerProfile",
      "kind": "LinkedField",
      "name": "learnerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerProfilePracticeScore",
          "kind": "LinkedField",
          "name": "practiceScores",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Practice",
              "kind": "LinkedField",
              "name": "practice",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "score",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "managerScore",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerPractice",
      "kind": "LinkedField",
      "name": "practice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "d13088b9225136d307c552ed13602152";

export default node;
