import { type SyntheticEvent, useContext, useRef, useState } from 'react'

import { Button } from '@/components/Button'
import { Card, CardContent, CardTitle } from '@/components/Card'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select'
import { Link, Navigate, useNavigate } from '@tanstack/react-router'
import { LogOutIcon } from 'lucide-react'
import { Squash as Hamburger } from 'hamburger-react'

import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay'
import { OrientationQuery } from './__generated__/OrientationQuery.graphql'
import { InteractionContext } from '@/components/Interaction'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { Orientation_learner$key } from './__generated__/Orientation_learner.graphql'

const Fragment = graphql`
  fragment Orientation_learner on Learner {
    localizedLogoUrl
    seenWelcome
    fullName
    locale
    assignments {
      totalCount
    }
    customer {
      name
      aboutPageTitle
      aboutPageContent
      orientationVideoUrl
    }
  }
`

const Query = graphql`
  query OrientationQuery {
    learner {
      ...AppContainer_learner
      ...Orientation_learner
    }
  }
`

const SeenWelcomeMutation = graphql`
  mutation OrientationSeenWelcomeMutation {
    seenWelcome {
      id
      seenWelcome
    }
  }
`

const SetLocaleMutation = graphql`
  mutation OrientationSetLocaleMutation($input: LearnerUpdate!) {
    updateLearner(input: $input) {
      id
      locale
    }
  }
`

type OrientationComponentProps = {
  learner: Orientation_learner$key
}

function OrientationComponent({ learner: key }: OrientationComponentProps) {
  const data = useFragment(Fragment, key)
  const [currentPage, setCurrentPage] = useState(1)
  const [open, setOpen] = useState(false)
  const { aboutPageTitle, aboutPageContent } = data?.customer || {}
  const [updateSeenWelcome] = useMutation(SeenWelcomeMutation)
  const [setLocale] = useMutation(SetLocaleMutation)
  const hasCustomContent = aboutPageContent && aboutPageTitle

  const firstTimeLearner = data?.assignments.totalCount == 0

  const navigate = useNavigate()

  const videoFinished = useRef(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { interact } = useContext(InteractionContext)
  function videoInteract(n: string, other?: Record<string, unknown>) {
    interact(n, {
      ...(other || {}),
      videoFinished: videoFinished.current,
      videoTimestamp: videoRef?.current?.currentTime,
    })
  }

  const handleContinueConfirm = () => {
    videoInteract('continue_confirm')
    updateSeenWelcome({
      variables: {},
      onCompleted: () => {
        navigate({ to: '/lms' })
      },
    })
  }

  const availableLocales = [
    { value: 'en-US', label: 'English' },
    { value: 'fr-CA', label: 'Français' },
  ]

  const handleLocaleChange = (locale: string) => {
    setLocale({
      variables: {
        input: { locale },
      },
      onCompleted: () => {
        window.location.reload()
      },
    })
  }

  const videoUrl =
    data?.customer.orientationVideoUrl ||
    `https://client.flintls.com/2dot0/${
      firstTimeLearner ? 'OTJOrientation' : 'OTJOrientation_alt'
    }${data?.locale === 'fr-CA' ? '-FR' : ''}.mp4`

  function MenuItem({ page, title }: { page: number; title: string }) {
    return (
      <button
        className={`flex cursor-pointer items-center gap-2 ${
          page === currentPage ? 'opacity-100' : 'opacity-50'
        }`}
        onClick={() => setCurrentPage(page)}
      >
        <div
          className={`h-1.5 w-1.5 rounded-full ${
            page === currentPage ? 'bg-flintBlue' : 'bg-slate-300'
          } `}
        ></div>
        {title}
      </button>
    )
  }

  return (
    <>
      <div className="border-b border-b-slate-900/5 bg-white">
        <div className="contain flex items-center justify-between py-6">
          <div>
            {data?.localizedLogoUrl ? (
              <img
                src={data.localizedLogoUrl}
                alt={data.customer.name}
                className="h-9"
                translate="no"
              />
            ) : (
              <p className="text-2xl font-semibold sm:text-3xl" translate="no">
                {data?.customer.name}
              </p>
            )}
          </div>
          <div className="hidden gap-6 text-sm sm:flex">
            <Select onValueChange={handleLocaleChange}>
              <SelectTrigger className="w-[180px]">
                <SelectValue
                  placeholder={
                    availableLocales.find(
                      (locale) => locale.value === data?.locale
                    )?.label
                  }
                />
              </SelectTrigger>
              <SelectContent>
                {availableLocales.map((locale) => (
                  <SelectItem
                    key={locale.value}
                    value={locale.value}
                    translate="no"
                  >
                    {locale.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div>
              <span className="font-semibold" translate="no">
                {data?.fullName}
              </span>
              <Link
                to="/lms/logout"
                className="flex items-center justify-end gap-1.5 text-slate-400 transition-colors hover:text-red-500"
              >
                <span>Logout</span>
                <LogOutIcon className="w-3.5" />
              </Link>
            </div>
          </div>
          <div className="sm:hidden">
            <Hamburger toggled={open} toggle={setOpen} size={24} />
          </div>
        </div>
        <div>
          <div
            className={` contain grid gap-8 border-t border-t-slate-200 py-8 font-medium sm:hidden ${
              open ? 'block' : 'hidden'
            }`}
          >
            <Link to="/lms/logout" className="flex w-full gap-1.5">
              <span>Logout</span>
              <LogOutIcon strokeWidth={2.5} className="w-3.5" />
            </Link>
          </div>
        </div>
      </div>
      <div className="pb-24 pt-12">
        <div className="contain">
          <Card className="text-sm">
            <CardContent
              className={`${hasCustomContent && 'md:flex md:divide-x'}`}
            >
              {hasCustomContent ? (
                <div className="hidden flex-shrink-0 space-y-2 md:block md:pr-12">
                  <MenuItem page={1} title="What Are On-the-Job Activities?" />
                  <MenuItem page={2} title={aboutPageTitle} />
                </div>
              ) : null}

              <div
                className={`flex-grow space-y-6 ${
                  hasCustomContent && 'md:pl-12'
                }`}
              >
                <div className="flex justify-between">
                  <div className="space-y-3">
                    <CardTitle>
                      {currentPage === 1
                        ? 'What Are On-the-Job Activities?'
                        : aboutPageTitle}
                    </CardTitle>
                  </div>
                  {currentPage === 2 && (
                    <>
                      {data?.localizedLogoUrl ? (
                        <img
                          src={data.localizedLogoUrl}
                          alt={data.customer.name}
                          className="h-9"
                          translate="no"
                        />
                      ) : (
                        <p className="font-semibold" translate="no">
                          {data?.customer.name}
                        </p>
                      )}
                    </>
                  )}
                </div>

                {currentPage === 1 && (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      ref={videoRef}
                      controls
                      poster={
                        data?.locale === 'fr-CA'
                          ? '/video-thumbnail-FR.png'
                          : '/video-thumbnail.png'
                      }
                      controlsList="nodownload"
                      className="aspect-video w-full rounded-lg border border-slate-950/5"
                      onPlay={() => videoInteract('video_play')}
                      onEnded={() => {
                        videoFinished.current = true
                        videoInteract('video_finish')
                      }}
                      onPause={(event: SyntheticEvent<HTMLVideoElement>) => {
                        // `onPause` is also triggered when seeking or ending,
                        // but we only want to log manual pauses here.
                        if (
                          !event.currentTarget.seeking &&
                          !event.currentTarget.ended
                        ) {
                          videoInteract('video_pause')
                        }
                      }}
                      onSeeked={() => videoInteract('video_seek')}
                    >
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}

                {currentPage === 2 && aboutPageContent ? (
                  <Markdown>{aboutPageContent}</Markdown>
                ) : null}

                <div className="flex justify-end gap-3">
                  {currentPage === 2 && (
                    <Button
                      onClick={() => {
                        setCurrentPage(1)
                        videoInteract('tab_change', { tab: 1 })
                      }}
                    >
                      Previous
                    </Button>
                  )}
                  {aboutPageContent && currentPage == 1 ? (
                    <>
                      <Button
                        onClick={() => {
                          setCurrentPage(2)
                          videoInteract('tab_change', { tab: 2 })
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    (!aboutPageContent ||
                      (aboutPageContent && currentPage === 2)) && (
                      <Dialog>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Leave orientation page?</DialogTitle>
                          </DialogHeader>
                          <p>
                            This page will not be accessible after you leave.
                            But you can view this orientation content any time
                            in the About tab.
                          </p>
                          <DialogFooter>
                            <DialogClose>
                              <Button
                                onClick={() => videoInteract('continue_cancel')}
                                variant="secondary"
                                size="sm"
                              >
                                Cancel
                              </Button>
                            </DialogClose>
                            <Button size="sm" onClick={handleContinueConfirm}>
                              Confirm
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                        <DialogTrigger>
                          <Button onClick={() => videoInteract('continue')}>
                            Continue
                            {firstTimeLearner ? ' to Survey' : null}
                          </Button>
                        </DialogTrigger>
                      </Dialog>
                    )
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  )
}

function Orientation() {
  const data = useLazyLoadQuery<OrientationQuery>(Query, {})
  if (!data.learner) {
    return <Navigate to="/lms" />
  }
  return (
    <AppContainer page="orientation" learner={data.learner}>
      <OrientationComponent learner={data.learner} />
    </AppContainer>
  )
}

export default Orientation
