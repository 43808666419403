/**
 * @generated SignedSource<<de41540bb26c74920091ad369588654f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityFragment$data = {
  readonly activity: {
    readonly difficulty: number;
    readonly eligibleForAssignment: boolean;
    readonly guidance: string | null;
    readonly id: string;
    readonly instructions: string;
    readonly name: string;
    readonly shortDescription: string;
  };
  readonly id: string;
  readonly initiative: {
    readonly id: string;
  } | null;
  readonly learner: {
    readonly currentAssignment: {
      readonly id: string;
    } | null;
    readonly practice: {
      readonly difficulty: number;
      readonly easierActivity: {
        readonly id: string;
      } | null;
      readonly harderActivity: {
        readonly id: string;
      } | null;
      readonly id: string;
      readonly nextActivity: {
        readonly id: string;
      } | null;
    } | null;
  };
  readonly notes: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendarFragment">;
  readonly " $fragmentType": "ActivityFragment";
};
export type ActivityFragment$key = {
  readonly " $data"?: ActivityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddToCalendarFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "learner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "currentAssignment",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerPractice",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "nextActivity",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "easierActivity",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "harderActivity",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortDescription",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instructions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guidance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eligibleForAssignment",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "initiative",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "84f8ea557de2928bccfa09f2789f7f51";

export default node;
