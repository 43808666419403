/**
 * @generated SignedSource<<cb4c8e3e01fcd8a616d518d2a92e1371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyLearnerSurveySubmitResponseMutation$variables = {
  response: string;
};
export type MyLearnerSurveySubmitResponseMutation$data = {
  readonly submitSurveyResponse: {
    readonly id: string;
    readonly learner: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"MyLearnerSurveyFragment">;
    };
  } | null;
};
export type MyLearnerSurveySubmitResponseMutation = {
  response: MyLearnerSurveySubmitResponseMutation$data;
  variables: MyLearnerSurveySubmitResponseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "response"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "response",
    "variableName": "response"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "longDescription",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyLearnerSurveySubmitResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "submitSurveyResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MyLearnerSurveyFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyLearnerSurveySubmitResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "submitSurveyResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lmsUserID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponse",
                "kind": "LinkedField",
                "name": "pendingSurveyFromManager",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Survey",
                    "kind": "LinkedField",
                    "name": "survey",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "instructions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveyResponseSection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SurveyAnswer",
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SurveyQuestion",
                            "kind": "LinkedField",
                            "name": "question",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prompt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Skill",
                                "kind": "LinkedField",
                                "name": "behavior",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Practice",
                                "kind": "LinkedField",
                                "name": "practices",
                                "plural": true,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceLabels",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "multipleResponses",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastIsNoneOfTheAbove",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choices",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ordinal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "previousChoice",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyAssignment",
                "kind": "LinkedField",
                "name": "surveyAwaitingResponseFromManager",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14be04262346fd3eb863408a5abc7b0c",
    "id": null,
    "metadata": {},
    "name": "MyLearnerSurveySubmitResponseMutation",
    "operationKind": "mutation",
    "text": "mutation MyLearnerSurveySubmitResponseMutation(\n  $response: ID!\n) {\n  submitSurveyResponse(response: $response) {\n    id\n    learner {\n      id\n      ...MyLearnerSurveyFragment\n    }\n  }\n}\n\nfragment MyLearnerSurveyFragment on Learner {\n  id\n  ...TemplateLearnerFragment\n  ...QuestionCardLearnerFragment\n  pendingSurveyFromManager {\n    id\n    endDate\n    survey {\n      id\n      instructions\n      title\n    }\n    sections {\n      id\n      answers {\n        id\n        ...QuestionCardFragment\n      }\n    }\n    ...completedAnswersFragment\n  }\n  surveyAwaitingResponseFromManager {\n    id\n  }\n}\n\nfragment PromptAnswerFragment on SurveyAnswer {\n  question {\n    prompt\n    behavior {\n      id\n      name\n      longDescription\n    }\n    practices {\n      id\n      name\n      longDescription\n    }\n    id\n  }\n}\n\nfragment PromptLearnerFragment on Learner {\n  ...TemplateLearnerFragment\n}\n\nfragment QuestionCardFragment on SurveyAnswer {\n  id\n  ...PromptAnswerFragment\n  question {\n    id\n    choiceCount\n    choiceLabels\n    multipleResponses\n    lastIsNoneOfTheAbove\n  }\n  choice\n  choices\n  text\n  ordinal\n  previousChoice\n}\n\nfragment QuestionCardLearnerFragment on Learner {\n  ...PromptLearnerFragment\n}\n\nfragment TemplateLearnerFragment on Learner {\n  email\n  fullName\n  lmsUserID\n}\n\nfragment completedAnswersFragment on SurveyResponse {\n  sections {\n    answers {\n      id\n      question {\n        choiceCount\n        id\n      }\n      choice\n      choices\n      text\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae2a17b865cbf41e3fd5c0a528a7267a";

export default node;
