/**
 * @generated SignedSource<<f09126d482e646638e1def1dc0db134b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstTimeDialogLearnerFragment$data = {
  readonly achievementCategories: ReadonlyArray<{
    readonly achievements: ReadonlyArray<{
      readonly acknowledged: boolean;
      readonly completionDate: number | null;
    }>;
  }>;
  readonly " $fragmentType": "FirstTimeDialogLearnerFragment";
};
export type FirstTimeDialogLearnerFragment$key = {
  readonly " $data"?: FirstTimeDialogLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstTimeDialogLearnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstTimeDialogLearnerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerAchievementCategory",
      "kind": "LinkedField",
      "name": "achievementCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerAchievement",
          "kind": "LinkedField",
          "name": "achievements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completionDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "178f4b980af65d53406676a584176f0e";

export default node;
