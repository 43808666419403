import { Hero, HeroTitle } from '@/components/lms/Hero'
import { Link } from '@tanstack/react-router'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { NotFoundQuery } from './__generated__/NotFoundQuery.graphql'
import { graphql } from 'react-relay'
import { useLazyLoadQuery } from 'react-relay'

export function NotFoundComponent() {
  return (
    <>
      <Hero>
        <HeroTitle>We couldn&#39;t find what you were looking for!</HeroTitle>
      </Hero>
      <div className="space-y-4 text-sm">
        <p>
          You&#39;ve requested a page that doesn&#39;t exist. Please start from
          your{' '}
          <Link to="/lms" className="text-flintBlue hover:underline">
            home page
          </Link>{' '}
          or use your browser&#39;s &#34;Back&#34; button.
        </p>
        <p>
          If you were sent here by one of our links, please{' '}
          <a
            href="mailto:support@flintls.com"
            className="text-flintBlue hover:underline"
          >
            contact us
          </a>{' '}
          so that we can correct our mistake.
        </p>
      </div>
    </>
  )
}

function NotFound() {
  const data = useLazyLoadQuery<NotFoundQuery>(
    graphql`
      query NotFoundQuery {
        learner {
          ...AppContainer_learner
        }
      }
    `,
    {}
  )
  return (
    <AppContainer page="not-found" learner={data.learner}>
      <NotFoundComponent />
    </AppContainer>
  )
}

export default NotFound
