import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { graphql, useFragment, useMutation } from 'react-relay'
import { NewAchievementDialogFragment$key } from './__generated__/NewAchievementDialogFragment.graphql'
import { NewAchievementDialogMutation } from './__generated__/NewAchievementDialogMutation.graphql'

const Fragment = graphql`
  fragment NewAchievementDialogFragment on Learner {
    achievementCategories {
      achievements {
        acknowledged
        completionDate
        achievement {
          id
          name
          completionDescription
          showCompletion
          imageUrl
          startDate
        }
      }
    }
  }
`

const Mutation = graphql`
  mutation NewAchievementDialogMutation($achievement: ID!) {
    acknowledgeAchievement(achievement: $achievement) {
      ...NewAchievementDialogFragment
    }
  }
`

type NewAchievementDialogProps = {
  edgeRef: NewAchievementDialogFragment$key
}

// TODO: should this be an acknowledge dialog instead of only marking on close?
export function NewAchievementDialog({ edgeRef }: NewAchievementDialogProps) {
  const data = useFragment(Fragment, edgeRef)

  const [markSeen, marking] =
    useMutation<NewAchievementDialogMutation>(Mutation)

  const firstUnacknowledged = data.achievementCategories
    .flatMap((x) => x.achievements)
    .find(
      (x) =>
        !x.acknowledged &&
        x.achievement.showCompletion &&
        x.completionDate &&
        (!x.achievement.startDate || x.completionDate > x.achievement.startDate)
    )
  const dialogOpen = !!firstUnacknowledged && !marking

  const onClose = () => {
    if (!firstUnacknowledged) return
    markSeen({
      variables: {
        achievement: firstUnacknowledged.achievement.id,
      },
    })
  }

  // avoid constant type checks + unnecessary rendering
  if (!firstUnacknowledged || !firstUnacknowledged.completionDate) return null

  const achievement = firstUnacknowledged.achievement

  return (
    <Dialog open={dialogOpen} onOpenChange={onClose}>
      <DialogContent confetti closeBtn>
        <DialogHeader>
          <DialogTitle>{achievement.name}</DialogTitle>
          <DialogDescription className="sr-only">
            Completed Achievement
          </DialogDescription>
          <Markdown>{achievement.completionDescription}</Markdown>
        </DialogHeader>
        <div className="space-y-6 text-center">
          <div className="relative mx-auto w-3/4">
            <img
              src={achievement.imageUrl}
              alt="Achievement"
              className="h-auto w-full"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
