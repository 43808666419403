import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { Link, useNavigate } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'

import { Breadcrumb, Breadcrumbs } from '@/components/library/Breadcrumbs'
import { DataTable } from '@/components/DataTable'
import LoadingSuspense from '@/components/library/LoadingSuspense'

import type {
  AllPathwaysQuery,
  AllPathwaysQuery$data,
} from './__generated__/AllPathwaysQuery.graphql'

const Query = graphql`
  query AllPathwaysQuery {
    pathways {
      nodes {
        id
        name
      }
    }
  }
`

type Pathway = NonNullable<AllPathwaysQuery$data['pathways']>['nodes'][number]

const columns: ColumnDef<Pathway>[] = [
  {
    accessorKey: 'name',
    header: 'Pathway Name',
  },
]

export default function AllPathwaysPage() {
  const navigate = useNavigate()
  const data = useLazyLoadQuery<AllPathwaysQuery>(
    Query,
    {},
    {
      fetchPolicy: 'store-and-network',
    }
  )
  const pathways = data.pathways.nodes.map(({ id, name }) => ({ id, name }))

  return (
    <LoadingSuspense>
      <Breadcrumbs>
        <Breadcrumb home>
          <Link to="/library">Home</Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to="/library/all-pathways">All Pathways</Link>
        </Breadcrumb>
      </Breadcrumbs>
      <DataTable
        columns={columns}
        data={pathways}
        onRowClick={(row) =>
          navigate({
            from: '/library/all-pathways',
            to: '/library/pathway/$id',
            params: { id: row.id },
          })
        }
      />
    </LoadingSuspense>
  )
}
