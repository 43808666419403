import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { graphql, useFragment } from 'react-relay'
import { Link } from '@tanstack/react-router'
import { CompletedActivitiesCard_assignments$key } from './__generated__/CompletedActivitiesCard_assignments.graphql'
import { Loader2, XIcon } from 'lucide-react'

import { activityName } from '@/common/utils'

const Fragment = graphql`
  fragment CompletedActivitiesCard_assignments on Assignment
  @relay(plural: true) {
    id
    activity {
      name
    }
    completionDate
  }
`

interface CompletedActivitiesCardProps {
  assignments: CompletedActivitiesCard_assignments$key
  learnerIsCurrent: boolean
  onFocus?: () => void
  onBlur?: () => void
  focused?: boolean
}

export function CompletedActivitiesCard({
  assignments,
  learnerIsCurrent,
  onFocus,
  onBlur,
  focused = false,
}: CompletedActivitiesCardProps) {
  const completedActivities = useFragment(Fragment, assignments)
    .map((assignment) => ({
      name: activityName(assignment.activity),
      completionDate: assignment.completionDate,
      id: assignment.id,
    }))
    // Reverse order of completion.
    .sort((a, b) =>
      !a.completionDate
        ? -1
        : !b.completionDate
          ? 1
          : b.completionDate - a.completionDate
    )

  return (
    <Card
      className={`transition-all ${focused ? 'z-50 lg:ml-[-100%] lg:mr-[-100%]' : 'z-0 ml-0 mr-0'}`}
    >
      <CardHeader className="relative">
        <CardTitle size="sm">Activities</CardTitle>
        {focused ? (
          <button
            className="absolute right-4 top-4 opacity-40 duration-200 hover:text-red-500 hover:opacity-100"
            onClick={onBlur}
          >
            <XIcon size={22} />
          </button>
        ) : null}
      </CardHeader>
      {completedActivities.length > 0 ? (
        <CardContent className="space-y-6 text-sm">
          <ul className="divide-y divide-slate-950/5 font-medium [&>*]:py-2 first:[&>*]:pt-0 last:[&>*]:pb-0">
            {completedActivities
              .slice(0, !focused ? 3 : undefined)
              .map((assignment) => (
                <Link
                  key={assignment.id}
                  to={
                    assignment.completionDate || !learnerIsCurrent
                      ? '/lms/past-activity/$assignmentID'
                      : '/lms/activity'
                  }
                  params={{ assignmentID: assignment.id }}
                  className="flex justify-between duration-200 hover:opacity-60"
                >
                  <li className="flex w-full items-center justify-between gap-6">
                    <div>{assignment.name}</div>
                    <div
                      className={`flex flex-shrink-0 items-center gap-1.5 text-xs uppercase text-slate-600 ${!assignment.completionDate && 'animate-pulse'}`}
                    >
                      {!assignment.completionDate && (
                        <Loader2
                          size={16}
                          className="animate-spin text-flintBlue"
                        />
                      )}
                      {assignment.completionDate
                        ? new Date(
                            assignment.completionDate
                          ).toLocaleDateString()
                        : 'In Progress'}
                    </div>
                  </li>
                </Link>
              ))}
            {completedActivities.length > 3 && !focused ? (
              <li className="flex w-full items-center justify-between gap-6">
                <div>
                  <Link onClick={onFocus}>
                    +{completedActivities.length - 3} more
                  </Link>
                </div>
              </li>
            ) : null}
          </ul>
        </CardContent>
      ) : (
        <CardContent className="space-y-6 text-sm">
          <p>Activities completed during this initiative will appear here.</p>
        </CardContent>
      )}
    </Card>
  )
}
