/**
 * @generated SignedSource<<d4f24ae5dc07a666916636ce6d079a40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Orientation_learner$data = {
  readonly assignments: {
    readonly totalCount: number;
  };
  readonly customer: {
    readonly aboutPageContent: string | null;
    readonly aboutPageTitle: string | null;
    readonly name: string;
    readonly orientationVideoUrl: string | null;
  };
  readonly fullName: string | null;
  readonly locale: string;
  readonly localizedLogoUrl: string | null;
  readonly seenWelcome: boolean;
  readonly " $fragmentType": "Orientation_learner";
};
export type Orientation_learner$key = {
  readonly " $data"?: Orientation_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"Orientation_learner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Orientation_learner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedLogoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seenWelcome",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aboutPageTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aboutPageContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orientationVideoUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "7f34f90a10ef2bf2123209bd0386ca86";

export default node;
