import { format } from 'date-fns'
import { CheckCircle2 } from 'lucide-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { Navigate } from '@tanstack/react-router'

import { activityName } from '@/common/utils'
import { DUE_DATE_CUTOFF } from '@/common/hardcodes'
import { Button } from '@/components/Button'
import { ColumnDef, DataTable, SortableHeader } from '@/components/DataTable'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { Hero, HeroTitle, HeroSubtitle } from '@/components/lms/Hero'

import type {
  MyLearnersQuery,
  MyLearnersQuery$data,
} from './__generated__/MyLearnersQuery.graphql'

const Query = graphql`
  query MyLearnersQuery($dueDateCutoff: Float) {
    learner {
      ...AppContainer_learner
      id
      directReports {
        nodes {
          id
          fullName
          email
          assignments(filter: { feedbackCompleted: true }) {
            totalCount
          }
          availableAssignments {
            id
            dueDate
            activity {
              name
            }
          }
          currentAssignment {
            dueDate
            activity {
              name
            }
          }
          pendingSurveyFromManager {
            id
          }
          surveyAwaitingResponseFromManager {
            id
          }
          treatments {
            id
            treatmentType
          }
        }
      }
      overdue: directReports(filter: { dueBefore: $dueDateCutoff }) {
        nodes {
          id
        }
      }
      surveyResponses {
        nodes {
          learnerDescribed {
            id
          }
        }
      }
    }
  }
`

type Learner = NonNullable<
  MyLearnersQuery$data['learner']
>['directReports']['nodes'][number] & { overdue?: boolean }

const columns: ColumnDef<Learner>[] = [
  {
    accessorFn: (learner) =>
      Boolean(
        learner.surveyAwaitingResponseFromManager ??
          learner.pendingSurveyFromManager
      ),
    id: 'Action Item',
    cell: ({ row }) => {
      const learner = row.original
      const awaiting = learner.surveyAwaitingResponseFromManager?.id
      const pending = learner.pendingSurveyFromManager?.id
      return (
        <div className="flex justify-center gap-2">
          {awaiting || pending ? (
            <Button>Have 1-on-1</Button>
          ) : (
            <>
              <div>Up to Date</div>
              <CheckCircle2 className="h-5 w-5 fill-green-500 text-white" />
            </>
          )}
        </div>
      )
    },
    header: SortableHeader,
  },
  {
    accessorFn: (learner) => learner.fullName,
    id: 'Name',
    cell: ({ row }) => <var>{row.original.fullName}</var>,
    header: SortableHeader,
  },
  {
    accessorFn: (learner) => learner.email,
    id: 'Email',
    cell: ({ row }) => <var>{row.original.email}</var>,
    header: SortableHeader,
  },
  {
    accessorFn: (learner) => learner, // Required for title to appear?
    id: 'Current Activity or Choices',
    cell: function ({ row }) {
      const learner = row.original
      const current = learner.currentAssignment?.activity
      if (current) {
        return <div>{activityName(current)}</div>
      }
      const available = learner.availableAssignments
      if (available.length) {
        return (
          <ul className="list-inside list-disc">
            {available.map((a) => (
              <li key={a.id}>{activityName(a.activity)}</li>
            ))}
          </ul>
        )
      }
    },
  },
  {
    accessorFn: (learner) =>
      learner.currentAssignment?.dueDate ||
      learner.availableAssignments?.[0]?.dueDate ||
      null,
    id: 'Due Date',
    header: SortableHeader,
    size: 120,
    cell: function ({ getValue, row }) {
      const dueDate = getValue() as number | null
      return (
        <div
          className={
            'text-center' + (row.original.overdue ? ' text-red-500' : '')
          }
        >
          <var>{dueDate ? format(new Date(dueDate), 'MMM do') : null}</var>
        </div>
      )
    },
  },
  {
    accessorFn: (learner) => learner.assignments.totalCount,
    id: 'Completed',
    header: SortableHeader,
    size: 1,
    cell: function ({ getValue }) {
      return <div className="text-center">{getValue() as number}</div>
    },
  },
]

function MyLearners() {
  const data = useLazyLoadQuery<MyLearnersQuery>(Query, {
    dueDateCutoff: DUE_DATE_CUTOFF,
  })
  const me = data.learner
  if (!me) {
    return <Navigate to="/lms" from="/lms/my-learners" />
  }

  // TODO: This is currently a bit silly (we could do the same computation
  // clientside) but what I really want is a serverside value corresponding to
  // whether the learner has received notice of being overdue.
  const overdue = me.overdue.nodes.map((l) => l.id)
  const directReports = me.directReports.nodes
    .filter((l) => !l.treatments.some((t) => t.treatmentType === 'HeldOut'))
    .map((l) => ({
      ...l,
      overdue: overdue.includes(l.id),
    }))

  const hasOneOnOnes =
    directReports.some(
      (l) => l.surveyAwaitingResponseFromManager || l.pendingSurveyFromManager
    ) || me.surveyResponses.nodes.some((r) => r.learnerDescribed)

  return (
    <AppContainer page="my-learners" learner={me}>
      <Hero>
        <HeroTitle>My Learners</HeroTitle>
        <HeroSubtitle>
          View the progress of your direct reports and their assigned
          activities.
        </HeroSubtitle>
      </Hero>
      <DataTable
        columns={hasOneOnOnes ? columns : columns.slice(1)}
        data={directReports}
        initialSortState={[
          { id: 'Completed', desc: true },
          { id: 'Due Date', desc: true },
        ]}
        rowLink={(row) => ({
          to: '/lms/my-learners/$myLearnerID',
          params: { myLearnerID: row.id },
        })}
      />
    </AppContainer>
  )
}

export default MyLearners
