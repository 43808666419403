import { atcb_action } from 'add-to-calendar-button'

import { Button } from '../Button'
import { Apple, GoogleCalendar, Microsoft365 } from '../Icons'
import { useContext } from 'react'
import { InteractionContext } from '../Interaction'
import { graphql, useFragment } from 'react-relay'
import { AddToCalendarFragment$key } from './__generated__/AddToCalendarFragment.graphql'

type CalendarType = 'Google' | 'Microsoft365' | 'Apple'

const Fragment = graphql`
  fragment AddToCalendarFragment on Assignment {
    id
    activity {
      name
      shortDescription
      instructions
    }
  }
`

export type AddToCalendarButtonProps = {
  calendarType: CalendarType
  assignment: AddToCalendarFragment$key
}

export function AddToCalendarButton({
  calendarType,
  assignment,
}: AddToCalendarButtonProps) {
  const { interact } = useContext(InteractionContext)
  const data = useFragment(Fragment, assignment)

  function handleAdd(calendarType: CalendarType) {
    interact('click_addToCalendar', { calendarType })

    // 2 hours from now
    const start = new Date(new Date().getTime() + 1000 * 60 * 60 * 2)
    atcb_action({
      name: data?.activity?.name,
      uid: data?.id,
      options: [calendarType],
      description:
        calendarType === 'Google'
          ? data?.activity?.instructions
          : data?.activity?.shortDescription,
      startDate: 'today',
      useUserTZ: true,
      startTime: start.toISOString().slice(11, 13) + ':00',
      endTime:
        new Date(start.getTime() + 1000 * 60 * 15).toISOString().slice(11, 13) +
        ':15',
      hideBranding: true,
    })
  }

  const icon =
    calendarType === 'Google' ? (
      <>
        <GoogleCalendar size={16} />
        <var>Google</var>
      </>
    ) : calendarType === 'Microsoft365' ? (
      <>
        <Microsoft365 size={16} />
        <var>Microsoft365</var>
      </>
    ) : calendarType === 'Apple' ? (
      <>
        <Apple size={13} className="relative -top-px" />
        <var>Apple</var>
      </>
    ) : null

  return (
    <Button
      variant="secondary"
      onClick={() => handleAdd(calendarType)}
      size="xs"
    >
      {icon}
    </Button>
  )
}
