/**
 * @generated SignedSource<<ac87d7ef88cd6ea59263869d98b85698>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LearnerUpdate = {
  demoMode?: boolean | null;
  email?: string | null;
  fullName?: string | null;
  groups?: ReadonlyArray<string> | null;
  jobTitle?: string | null;
  locale?: string | null;
  manager?: string | null;
  removeManager?: boolean | null;
  testing?: boolean | null;
};
export type LocaleSelectMutation$variables = {
  input: LearnerUpdate;
};
export type LocaleSelectMutation$data = {
  readonly updateLearner: {
    readonly id: string;
    readonly locale: string;
  } | null;
};
export type LocaleSelectMutation = {
  response: LocaleSelectMutation$data;
  variables: LocaleSelectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Learner",
    "kind": "LinkedField",
    "name": "updateLearner",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locale",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocaleSelectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocaleSelectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22e22e8bb137c5beda1e72456ee51a66",
    "id": null,
    "metadata": {},
    "name": "LocaleSelectMutation",
    "operationKind": "mutation",
    "text": "mutation LocaleSelectMutation(\n  $input: LearnerUpdate!\n) {\n  updateLearner(input: $input) {\n    id\n    locale\n  }\n}\n"
  }
};
})();

(node as any).hash = "84681b71d8c583281768815d1e32f76c";

export default node;
