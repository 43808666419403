/**
 * @generated SignedSource<<363cf267bee190f46f6ad850ea252a83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompleteActivity_assignment$data = {
  readonly activity: {
    readonly name: string;
  };
  readonly id: string;
  readonly learner: {
    readonly currentInitiative: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"goalProgressHookEdgeFragment">;
      }>;
    };
    readonly practice: {
      readonly id: string;
      readonly practice: {
        readonly name: string;
      };
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"goalProgressHookLearnerFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFragment">;
  readonly " $fragmentType": "CompleteActivity_assignment";
};
export type CompleteActivity_assignment$key = {
  readonly " $data"?: CompleteActivity_assignment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompleteActivity_assignment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompleteActivity_assignment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activity",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "learner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerPractice",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Practice",
              "kind": "LinkedField",
              "name": "practice",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "goalProgressHookLearnerFragment"
        },
        {
          "alias": "currentInitiative",
          "args": [
            {
              "kind": "Literal",
              "name": "membershipFilter",
              "value": {
                "atDate": 0
              }
            }
          ],
          "concreteType": "LearnerInitiativeConnection",
          "kind": "LinkedField",
          "name": "initiatives",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LearnerInitiativeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "goalProgressHookEdgeFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Initiative",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "1063109f3e814241f129f125559921ec";

export default node;
