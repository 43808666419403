import { Card, CardContent, CardFooter } from '@/components/Card'
import { Button } from '@/components/Button'
import { graphql, useFragment } from 'react-relay'
import { CompleteActivity_assignment$key } from './__generated__/CompleteActivity_assignment.graphql'
import { CheckCircle } from 'lucide-react'
import { useGoalProgress } from '@/components/lms/Initiatives/goal-progress-hook'

const Fragment = graphql`
  fragment CompleteActivity_assignment on Assignment {
    id
    ...ActivityFragment
    activity {
      name
    }
    learner {
      practice {
        id
        practice {
          name
        }
      }
      ...goalProgressHookLearnerFragment
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          ...goalProgressHookEdgeFragment
          node {
            id
            name
          }
        }
      }
    }
  }
`

type CompletedActivityProps = {
  assignmentRef: CompleteActivity_assignment$key

  onContinue: () => void
  showPractice?: boolean
  showInitiative?: boolean
}

function CompletedActivity({
  assignmentRef,
  onContinue,
  showPractice = true,
  showInitiative = true,
}: CompletedActivityProps) {
  const assignment = useFragment(Fragment, assignmentRef)
  const practice = assignment.learner.practice
  const initiative =
    assignment.learner.currentInitiative.edges.length > 0
      ? assignment.learner.currentInitiative.edges[0]
      : undefined

  const { totalSteps, stepsCompleted } = useGoalProgress(
    assignment.learner,
    initiative
  )

  return (
    <div className="grid gap-4">
      <Card>
        <CardContent className="space-y-4">
          <div className="flex items-center gap-2">
            <CheckCircle className="h-4 w-4 text-green-600" /> Nice work
            finishing <strong>{assignment.activity.name}</strong>
          </div>
          {practice?.practice?.name ? (
            <p
              className={`text-sm opacity-0 transition-opacity duration-200 ${
                showPractice ? 'opacity-100' : 'opacity-0'
              }`}
            >
              You got better at <strong>{practice.practice?.name}</strong>.
            </p>
          ) : null}
          {initiative?.node?.name ? (
            <p
              className={`text-sm opacity-0 transition-opacity duration-200 ${
                showInitiative ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {totalSteps === stepsCompleted ? (
                <span>
                  You completed your initiative,{' '}
                  <strong>{initiative?.node?.name}</strong>!
                </span>
              ) : stepsCompleted > totalSteps ? (
                <span>
                  You are up to {stepsCompleted - totalSteps} bonus activities
                  in your initiative, <strong>{initiative?.node?.name}</strong>.
                </span>
              ) : (
                <span>
                  Only {totalSteps - stepsCompleted} activities left to go in
                  your initiative, <strong>{initiative?.node?.name}</strong>.
                </span>
              )}
            </p>
          ) : null}
        </CardContent>
        <CardFooter className="flex justify-end">
          <Button variant="outline" onClick={onContinue}>
            Continue
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

export default CompletedActivity
