/**
 * @generated SignedSource<<6db784afa8323d4fd3d6446a3f2214c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromptLearnerFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TemplateLearnerFragment">;
  readonly " $fragmentType": "PromptLearnerFragment";
};
export type PromptLearnerFragment$key = {
  readonly " $data"?: PromptLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromptLearnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromptLearnerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateLearnerFragment"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "4d1dab1c1a89fc8450d9b147f2417075";

export default node;
