import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import { Calendar, Lock } from 'lucide-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { AchievementsQuery } from './__generated__/AchievementsQuery.graphql'
import Markdown from '@/components/Markdown'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const Query = graphql`
  query AchievementsQuery {
    learner {
      ...AppContainer_learner
      id
      achievementCategories {
        category {
          id
          name
          description
          ordinal
        }
        achievements {
          completionDate
          achievement {
            id
            name
            description
            imageUrl
            startDate
          }
        }
      }
    }
  }
`

export default function Achievements() {
  const data = useLazyLoadQuery<AchievementsQuery>(Query, {})
  const achievementCategories = [...(data.learner?.achievementCategories ?? [])]
    .sort((a, b) => a.category.ordinal - b.category.ordinal)
    .filter((group) => group.achievements.length > 0)

  return (
    <AppContainer page="achievements" learner={data.learner}>
      <Hero>
        <HeroTitle>Achievements</HeroTitle>
      </Hero>
      {achievementCategories.map((group) => (
        <Card key={group.category.id} className="fill-container">
          <CardHeader>
            <CardTitle size="sm">{group.category.name}</CardTitle>
            <CardDescription>{group.category.description}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 items-start gap-10 sm:grid-cols-4 md:flex">
              {[...group.achievements]
                .sort((a, b) => {
                  if (!a.completionDate) return 1
                  if (!b.completionDate) return -1
                  return (
                    new Date(b.completionDate).getTime() -
                    new Date(a.completionDate).getTime()
                  )
                })
                .map((achievement) => (
                  <Dialog key={achievement.achievement.id}>
                    <DialogContent closeBtn>
                      <DialogHeader>
                        <DialogTitle>
                          {achievement.achievement.name}
                        </DialogTitle>
                        <DialogDescription className="sr-only">
                          Achievement Description
                        </DialogDescription>
                        <Markdown>
                          {achievement.achievement.description}
                        </Markdown>
                      </DialogHeader>
                      <div className="space-y-6 text-center">
                        <div className="space-y-3">
                          <div className="flex items-center justify-center gap-1.5">
                            {achievement.completionDate ? (
                              <Calendar
                                size={16}
                                className="stroke-flintBlue"
                              />
                            ) : (
                              <Lock size={16} className="stroke-red-500" />
                            )}
                            {achievement.completionDate ? (
                              <p>
                                {new Date(
                                  achievement.completionDate
                                ).toLocaleDateString()}
                              </p>
                            ) : (
                              <p className="font-semibold text-red-500">
                                Not yet unlocked
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="relative mx-auto w-3/4">
                          {achievement.completionDate ? null : (
                            <img
                              src="/achievements/chains.png"
                              alt="Chains"
                              className="absolute inset-0 z-10"
                            />
                          )}
                          <img
                            src={achievement.achievement.imageUrl}
                            alt="Achievement"
                            className="h-auto w-full"
                          />
                        </div>
                      </div>
                    </DialogContent>
                    <DialogTrigger className="group w-24 space-y-2">
                      <div
                        className={`relative duration-500 group-hover:scale-105 ${achievement.completionDate ? '' : 'opacity-40 duration-500 group-hover:opacity-100'}`}
                      >
                        {achievement.completionDate ? null : (
                          <img
                            src="/achievements/chains.png"
                            alt="Chains"
                            className="absolute inset-0 z-10"
                          />
                        )}
                        <img
                          src={achievement.achievement.imageUrl}
                          alt="Achievement"
                          className="h-auto w-full"
                        />
                      </div>

                      <p
                        className={`text-sm font-semibold duration-200 ${achievement.completionDate ? 'group-hover:flintBlue' : 'opacity-40 group-hover:opacity-100'}`}
                      >
                        {achievement.achievement.name}
                      </p>
                    </DialogTrigger>
                  </Dialog>
                ))}
            </div>
          </CardContent>
        </Card>
      ))}
    </AppContainer>
  )
}
