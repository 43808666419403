/**
 * @generated SignedSource<<8db2bbae3f63001cba8a0af499782d7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeEdgeFragment$data = {
  readonly assignments: {
    readonly nodes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"CompletedActivitiesCard_assignments">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardEdgeFragment" | "InitiativeSummaryCardFragment" | "PracticesCardEdgeFragment" | "goalProgressHookEdgeFragment">;
  readonly " $fragmentType": "InitiativeEdgeFragment";
};
export type InitiativeEdgeFragment$key = {
  readonly " $data"?: InitiativeEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeEdgeFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "chosen": true,
            "feedbackCompleted": true
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CompletedActivitiesCard_assignments"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "assignments(filter:{\"chosen\":true,\"feedbackCompleted\":true})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeSummaryCardFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BehaviorsCardEdgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PracticesCardEdgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookEdgeFragment"
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "66d228f9ce7d204e3343abd10ab4e423";

export default node;
