import React from 'react'
import { VariantProps, cva } from 'class-variance-authority'

import Spinner from '@/components/Spinner'
import { cn } from '@/common/utils'

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-flintBlue text-white hover:bg-flintBlue/90',
        danger: 'bg-red-600 text-white hover:bg-red-600/90',
        secondary: 'bg-slate-200 hover:bg-slate-200/80',
        loading: 'bg-flintBlue/75 text-white cursor-wait',
        demo: 'bg-orange-200 hover:bg-orange-200/90 border border-4 border-orange-600 border-dashed ',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        outline: 'border  hover:bg-flintBlue/10',
      },
      size: {
        default: 'h-10 px-6 rounded-md',
        sm: 'h-9 px-3 rounded-md',
        xs: 'h-9 px-3 rounded-md text-xs',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  spin?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, spin, variant, size, children, ...props }, ref) => {
    const v = spin ? 'loading' : variant
    return (
      <button
        className={cn(buttonVariants({ variant: v, size, className }))}
        ref={ref}
        {...{ type: 'button', ...props }}
      >
        {v !== 'loading' ? (
          children
        ) : (
          <>
            <Spinner />
            {children}
          </>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'
