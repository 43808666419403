/**
 * @generated SignedSource<<31a0a06291a88894c30406d20011c9e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Activity_learner$data = {
  readonly availableAssignments: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly currentAssignment: {
    readonly choiceDate: number | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CompleteActivity_assignment">;
  } | null;
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly initiative: {
        readonly id: string;
        readonly showChoices: boolean;
      };
      readonly " $fragmentSpreads": FragmentRefs<"CompletedDialogEdgeFragment" | "FirstTimeDialogEdgeFragment">;
    }>;
  };
  readonly pastInitiatives: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly initiative: {
        readonly id: string;
        readonly showChoices: boolean;
      };
      readonly seenEnd: boolean;
      readonly startDate: number;
      readonly " $fragmentSpreads": FragmentRefs<"EndDialogEdgeFragment">;
    }>;
  };
  readonly pendingSurvey: {
    readonly id: string;
  } | null;
  readonly receivingActivities: boolean;
  readonly surveyAwaitingResponse: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AppContainer_learner" | "CheckCompletion_learner" | "CompletedDialogLearnerFragment" | "CurrentActivity_learner" | "EndDialogLearnerFragment" | "FirstTimeDialogLearnerFragment" | "GeneralSurveyFragment" | "SelectActivity_learner">;
  readonly " $fragmentType": "Activity_learner";
};
export type Activity_learner$key = {
  readonly " $data"?: Activity_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"Activity_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": "initiative",
  "args": null,
  "concreteType": "Initiative",
  "kind": "LinkedField",
  "name": "node",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showChoices",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Activity_learner",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "choiceDate",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CompleteActivity_assignment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "availableAssignments",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivingActivities",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurvey",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponse",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppContainer_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneralSurveyFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentActivity_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectActivity_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckCompletion_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedDialogLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FirstTimeDialogLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndDialogLearnerFragment"
    },
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CompletedDialogEdgeFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FirstTimeDialogEdgeFragment"
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": "pastInitiatives",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "beforeDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EndDialogEdgeFragment"
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": "seenEnd",
              "args": [
                {
                  "kind": "Literal",
                  "name": "popupType",
                  "value": "InitiativePopupEnd"
                }
              ],
              "kind": "ScalarField",
              "name": "seenPopup",
              "storageKey": "seenPopup(popupType:\"InitiativePopupEnd\")"
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"beforeDate\":0})"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "6e77231269a2328591b8a09cda24427c";

export default node;
