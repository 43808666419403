/**
 * @generated SignedSource<<b73844f61ca804a116e973da511bc97b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Contest_ParticipantTable$data = {
  readonly contest: {
    readonly aheadCompetitors: ReadonlyArray<{
      readonly daysOverdue: number;
      readonly displayName: string;
      readonly rank: number;
    }>;
    readonly behindCompetitors: ReadonlyArray<{
      readonly daysOverdue: number;
      readonly displayName: string;
      readonly rank: number;
    }>;
  };
  readonly daysOverdue: number;
  readonly learner: {
    readonly email: string | null;
    readonly fullName: string | null;
  };
  readonly rank: number;
  readonly " $fragmentType": "Contest_ParticipantTable";
};
export type Contest_ParticipantTable$key = {
  readonly " $data"?: Contest_ParticipantTable$data;
  readonly " $fragmentSpreads": FragmentRefs<"Contest_ParticipantTable">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysOverdue",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  (v1/*: any*/),
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Contest_ParticipantTable",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "learner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DaysOverdueContest",
      "kind": "LinkedField",
      "name": "contest",
      "plural": false,
      "selections": [
        {
          "alias": "aheadCompetitors",
          "args": [
            {
              "kind": "Literal",
              "name": "input",
              "value": {
                "after": 0,
                "before": 5
              }
            }
          ],
          "concreteType": "DaysOverdueContestCompetitor",
          "kind": "LinkedField",
          "name": "nearbyCompetitors",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": "nearbyCompetitors(input:{\"after\":0,\"before\":5})"
        },
        {
          "alias": "behindCompetitors",
          "args": [
            {
              "kind": "Literal",
              "name": "input",
              "value": {
                "after": 5,
                "before": 0
              }
            }
          ],
          "concreteType": "DaysOverdueContestCompetitor",
          "kind": "LinkedField",
          "name": "nearbyCompetitors",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": "nearbyCompetitors(input:{\"after\":5,\"before\":0})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DaysOverdueContestLearner",
  "abstractKey": null
};
})();

(node as any).hash = "d3277dedd7b89333179910c634f0ca29";

export default node;
