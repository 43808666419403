import { graphql } from 'react-relay'

import Markdown from '@/components/Markdown'
import { useFragment } from 'react-relay'
import { ActivityFragment$key } from './__generated__/ActivityFragment.graphql'
import {
  ArrowDown,
  ArrowUp,
  CalendarPlus,
  Check,
  Equal,
  LifeBuoy,
  Printer,
  RefreshCcw,
  Sparkles,
} from 'lucide-react'
import { Button } from '../Button'
import { activityName } from '@/common/utils'

import Notes from './ActivityNotes'
import { Link, useNavigate } from '@tanstack/react-router'
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogFooter,
} from '../Dialog'
import { AddToCalendarButton } from './AddToCalendar'
import { AddToCalendarFragment$key } from './__generated__/AddToCalendarFragment.graphql'
import { DialogDescription } from '@radix-ui/react-dialog'
import ReactSpeedometer from 'react-d3-speedometer'
import { useAssignActivity } from '@/routes/lms/Activity/activityHooks'

const Fragment = graphql`
  fragment ActivityFragment on Assignment {
    id
    ...AddToCalendarFragment
    learner {
      currentAssignment {
        id
      }
      practice {
        difficulty
        id
        nextActivity {
          id
        }
        easierActivity {
          id
        }
        harderActivity {
          id
        }
      }
    }
    activity {
      id
      name
      difficulty
      shortDescription
      instructions
      guidance
      eligibleForAssignment
    }
    notes
    initiative {
      id
    }
  }
`

type GudianceProps = {
  guidance: string | null
}
function Guidance({ guidance }: GudianceProps) {
  if (!guidance) return null

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="bg-white">
          <LifeBuoy className="h-4 w-4" />
          More Guidance
        </Button>
      </DialogTrigger>
      <DialogContent closeBtn size="lg">
        <DialogHeader>
          <DialogTitle>More Guidance</DialogTitle>
        </DialogHeader>
        <DialogDescription className="sr-only">
          More guidance for this activity.
        </DialogDescription>
        <Markdown>{guidance}</Markdown>
      </DialogContent>
    </Dialog>
  )
}

type AddToCalendarDialogProps = {
  assignment: AddToCalendarFragment$key
}

function AddToCalendarDialog({ assignment }: AddToCalendarDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="bg-white">
          <CalendarPlus className="h-4 w-4" />
          Schedule
        </Button>
      </DialogTrigger>
      <DialogContent closeBtn>
        <DialogHeader>
          <DialogTitle>Schedule</DialogTitle>
          <DialogDescription>
            Select the type of calendar you&apos;d like to add this activity to.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <div className="flex flex-wrap gap-2">
            <AddToCalendarButton
              assignment={assignment}
              calendarType="Google"
            />
            <AddToCalendarButton
              assignment={assignment}
              calendarType="Microsoft365"
            />
            <AddToCalendarButton assignment={assignment} calendarType="Apple" />
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type AdjustDifficultyDialogProps = {
  assignmentID: string
  easierActivity?: string
  harderActivity?: string
  nextActivity?: string
}

function AdjustDifficultyDialog({
  assignmentID,
  easierActivity,
  harderActivity,
  nextActivity,
}: AdjustDifficultyDialogProps) {
  const [assignActivity] = useAssignActivity(assignmentID)

  if (!easierActivity && !harderActivity) return null

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="h-auto p-2">
          <Sparkles className="h-4 w-4" />
          Change Activity
        </Button>
      </DialogTrigger>
      <DialogContent size="lg" closeBtn>
        <DialogHeader>
          <DialogTitle>Change Activity</DialogTitle>
          <DialogDescription className="text-sm">
            Replace this activity with another option at your preferred
            difficulty level.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            size="sm"
            disabled={!easierActivity}
            onClick={() =>
              easierActivity ? assignActivity(easierActivity) : null
            }
            className="w-full justify-center"
          >
            <ArrowDown className="mr-1.5 h-3.5  text-green-600" />
            Easier
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={!nextActivity}
            onClick={() => (nextActivity ? assignActivity(nextActivity) : null)}
            className="w-full justify-center"
          >
            <Equal className="mr-1.5 h-3.5  " />
            Same Difficulty
          </Button>
          <Button
            variant="outline"
            size="sm"
            disabled={!harderActivity}
            onClick={() =>
              harderActivity ? assignActivity(harderActivity) : null
            }
            className="w-full justify-center"
          >
            <ArrowUp className="mr-1.5 h-3.5  text-red-600" />
            Harder
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

type RedoButtonProps = {
  currentAssignmentID?: string
  activityID: string
}

function RedoButton({ currentAssignmentID, activityID }: RedoButtonProps) {
  const navigate = useNavigate()
  const [assignActivity] = useAssignActivity(currentAssignmentID)
  const handleRedo = () => {
    assignActivity(activityID, () => {
      navigate({
        to: '/lms/activity',
      })
    })
  }
  return (
    <Button
      variant="outline"
      size="sm"
      className="bg-white"
      onClick={handleRedo}
    >
      <RefreshCcw className="h-4 w-4" />
      Redo
    </Button>
  )
}

type ActivityProps = {
  assignment: ActivityFragment$key
  onComplete?: () => void
  variant: 'inProgress' | 'print' | 'completed' | 'redoable'
}

function Activity({ assignment, variant, onComplete }: ActivityProps) {
  const data = useFragment(Fragment, assignment)
  const activity = data.activity

  const easierActivity = data.learner?.practice?.easierActivity?.id
  const harderActivity = data.learner?.practice?.harderActivity?.id
  const nextActivity = data.learner?.practice?.nextActivity?.id

  const showDifficulty = variant != 'print' && activity.difficulty > 0
  return (
    <div>
      <h1 className="mb-8 text-xl font-semibold text-flintBlue">
        {activityName(activity)}
      </h1>

      {/* Main content grid */}
      <div
        className={showDifficulty ? 'grid gap-6 md:grid-cols-[auto,1fr]' : ''}
      >
        {/* Gauge section */}
        {showDifficulty ? (
          <div
            className="flex flex-col items-center justify-center gap-4 
                         text-center md:sticky md:top-4 md:w-48"
          >
            <div className="w-full max-w-[160px] md:w-48">
              <h3 className="font-semibold">Difficulty</h3>
              <div className="mx-auto ">
                <ReactSpeedometer
                  value={activity.difficulty}
                  minValue={0}
                  maxValue={10}
                  height={90}
                  width={160}
                  startColor="#84cc16"
                  endColor="#ef4444"
                  needleColor="#475569" // slate-600
                  labelFontSize="10px"
                  currentValueText=""
                  forceRender={true}
                />
              </div>
            </div>
            {variant === 'inProgress' ? (
              <div className="flex-1 md:w-full">
                <AdjustDifficultyDialog
                  assignmentID={data.id}
                  easierActivity={easierActivity}
                  harderActivity={harderActivity}
                  nextActivity={nextActivity}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {/* Main content section */}
        <div className="space-y-6">
          <Markdown>{activity.shortDescription}</Markdown>
          <div className="space-y-2">
            <h2 className="font-semibold">Instructions</h2>
            <Markdown>{activity.instructions}</Markdown>
          </div>
          {variant === 'print' && activity.guidance ? (
            <div className="space-y-2">
              <h2 className="font-semibold">More Guidance</h2>
              <Markdown>{activity.guidance}</Markdown>
            </div>
          ) : null}
          {data.notes && (variant === 'print' || variant === 'completed') ? (
            <div className="space-y-2">
              <h2 className="font-semibold">Notes</h2>
              <p className="text-sm">{data.notes}</p>
            </div>
          ) : null}
          {variant === 'completed' || variant === 'redoable' ? (
            <div>
              <div className="mt-8 flex w-full justify-end gap-2">
                <Guidance guidance={activity.guidance} />
                <Link
                  to="/lms/print-activity/$assignmentID"
                  params={{ assignmentID: data.id }}
                  target="_blank"
                >
                  <Button variant="outline" size="sm" className="bg-white">
                    <Printer className="h-4 w-4" />
                    Print
                  </Button>
                </Link>
                {variant === 'redoable' ? (
                  <RedoButton
                    currentAssignmentID={data.learner?.currentAssignment?.id}
                    activityID={activity.id}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          {variant === 'inProgress' ? (
            <>
              <div className="">
                <h2 className="font-semibold">Notes</h2>
                <p className="mb-4 text-xs text-slate-500">
                  Your notes automatically save. Your manager can&apos;t see
                  them.
                </p>
                <Notes assignmentID={data.id} notes={data.notes} />
              </div>
              <div>
                <div className="mt-8">
                  <div className="flex flex-wrap justify-end gap-2 sm:flex-row">
                    <Guidance guidance={activity.guidance} />
                    <AddToCalendarDialog assignment={data} />
                    <Link
                      to="/lms/print-activity/$assignmentID"
                      params={{ assignmentID: data.id }}
                      target="_blank"
                    >
                      <Button
                        variant="outline"
                        size="sm"
                        className="w-full bg-white 
                               sm:w-auto"
                      >
                        <Printer className="h-4 w-4" />
                        Print
                      </Button>
                    </Link>

                    {onComplete ? (
                      <Button
                        variant="outline"
                        size="sm"
                        className="w-full bg-white sm:w-auto"
                        onClick={onComplete}
                      >
                        <Check className="h-4 w-4" />
                        Mark Completed
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Activity
