import { graphql, useLazyLoadQuery } from 'react-relay'
import { LearningProfileQuery } from './__generated__/LearningProfileQuery.graphql'
import LearningProfileComp from '@/components/LearningProfile/LearningProfile'
import { Navigate } from '@tanstack/react-router'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const Query = graphql`
  query LearningProfileQuery {
    learner {
      ...AppContainer_learner
      ...LearningProfile_learner
    }
  }
`

function LearningProfile() {
  const data = useLazyLoadQuery<LearningProfileQuery>(Query, {})

  if (!data.learner) {
    return <Navigate to="/lms" />
  }

  return (
    <AppContainer page="profile" learner={data.learner}>
      <LearningProfileComp data={data.learner} />
    </AppContainer>
  )
}

export default LearningProfile
