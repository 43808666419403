/**
 * @generated SignedSource<<65b18b23e8a233fe38f030c8a7fc2e8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LibrariesQuery$variables = Record<PropertyKey, never>;
export type LibrariesQuery$data = {
  readonly libraries: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly imageUrl: string | null;
      readonly name: string;
      readonly shortDescription: string;
    }>;
  };
};
export type LibrariesQuery = {
  response: LibrariesQuery$data;
  variables: LibrariesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LibraryConnection",
    "kind": "LinkedField",
    "name": "libraries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LibrariesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LibrariesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d8191fd4256098d4b1c60d79c579347a",
    "id": null,
    "metadata": {},
    "name": "LibrariesQuery",
    "operationKind": "query",
    "text": "query LibrariesQuery {\n  libraries {\n    nodes {\n      id\n      name\n      shortDescription\n      imageUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcb41cc978589a30b19fc4ee42fa47f9";

export default node;
