/**
 * @generated SignedSource<<16f1792b724927d4099b11db160cb542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectActivity_learner$data = {
  readonly availableAssignments: ReadonlyArray<{
    readonly dueDate: number | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCard_assignment">;
  }>;
  readonly id: string;
  readonly treatments: ReadonlyArray<{
    readonly experiment: {
      readonly name: string;
    };
    readonly name: string;
  }>;
  readonly " $fragmentType": "SelectActivity_learner";
};
export type SelectActivity_learner$key = {
  readonly " $data"?: SelectActivity_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectActivity_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectActivity_learner",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "availableAssignments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityCard_assignment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Treatment",
      "kind": "LinkedField",
      "name": "treatments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Experiment",
          "kind": "LinkedField",
          "name": "experiment",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "60692bf5423f1f2eb81ac23cd76a0c31";

export default node;
