import { graphql, useFragment } from 'react-relay'

import Activity from '@/components/lms/Activity'
import { CurrentActivity_learner$key } from './__generated__/CurrentActivity_learner.graphql'
import { InitiativeStatus } from './InitiativeStatus'
import { Card, CardContent } from '@/components/Card'
import { useEffect, useRef, useState } from 'react'
import {
  useAssignActivity,
  useGenerateAssignments,
  useSetPractice,
} from './activityHooks'
import { CompleteActivity_assignment$key } from './__generated__/CompleteActivity_assignment.graphql'
import CompleteActivity from './CompleteActivity'

const Fragment = graphql`
  fragment CurrentActivity_learner on Learner {
    ...InitiativeStatusLearnerFragment
    readyForAssignments
    practice {
      id
      practice {
        id
        name
      }
      nextActivity {
        id
      }
      nextPractice {
        id
      }
    }
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      edges {
        node {
          id
          name
          practices {
            totalCount
          }
        }
        ...InitiativeStatusEdgeFragment
      }
    }
    currentAssignment {
      id
      ...ActivityFragment
    }
  }
`

type CurrentActivityProps = {
  learner: CurrentActivity_learner$key
  onComplete?: () => void
  congratulations?: CompleteActivity_assignment$key
  onContinue?: () => void
}

function CurrentActivity({
  learner: learnerFrag,
  congratulations,
  onContinue,
  onComplete,
}: CurrentActivityProps) {
  const learner = useFragment(Fragment, learnerFrag)
  const [firstPractice] = useState(learner.practice?.practice?.id)
  const [assignActivity, isAssigningActivity] = useAssignActivity(
    learner.currentAssignment?.id
  )
  const [setPractice, isSettingPractice] = useSetPractice(
    learner.currentAssignment?.id
  )
  const [generateAssignments, isGeneratingAssignments] =
    useGenerateAssignments()
  const workLock = useRef(false)

  const nextActivity = learner.practice?.nextActivity?.id
  const nextPractice = learner.practice?.nextPractice?.id
  const assignment = learner.currentAssignment
  const ready = learner.readyForAssignments
  const initiative =
    learner.currentInitiative.edges.length > 0
      ? learner.currentInitiative.edges[0]
      : null
  const practiceCount = initiative?.node?.practices?.totalCount || 0

  useEffect(() => {
    if (
      !ready ||
      assignment ||
      isAssigningActivity ||
      isSettingPractice ||
      isGeneratingAssignments ||
      workLock.current
    ) {
      return
    }
    if (practiceCount === 0) {
      workLock.current = true
      generateAssignments(() => {
        workLock.current = false
      })
    } else if (nextActivity) {
      workLock.current = true
      assignActivity(nextActivity, () => {
        workLock.current = false
      })
    } else {
      if (nextPractice != firstPractice || !nextPractice) {
        workLock.current = true
        setPractice(nextPractice, () => {
          workLock.current = false
        })
      }
    }
  }, [
    ready,
    firstPractice,
    assignment,
    isAssigningActivity,
    isSettingPractice,
    isGeneratingAssignments,
    setPractice,
    nextPractice,
    assignActivity,
    nextActivity,
    practiceCount,
    generateAssignments,
  ])

  const [initiativeAnimated, setInitiativeAnimated] = useState(false)
  const [practiceAnimated, setPracticeAnimated] = useState(false)

  useEffect(() => {
    if (congratulations) {
      setTimeout(() => {
        setInitiativeAnimated(true)
      }, 4000)
      setTimeout(() => {
        setPracticeAnimated(true)
      }, 2000)
    }
  }, [congratulations])

  return (
    <div className="fill-container space-y-4">
      <div>
        {initiative ? (
          <InitiativeStatus
            learnerRef={learner}
            initiativeRef={initiative}
            initiativeAnimated={initiativeAnimated}
            practiceAnimated={practiceAnimated}
            showNextPractice={!congratulations}
          />
        ) : null}
      </div>
      {congratulations && onContinue ? (
        <CompleteActivity
          assignmentRef={congratulations}
          onContinue={onContinue}
          showPractice={practiceAnimated}
          showInitiative={initiativeAnimated}
        />
      ) : assignment ? (
        <Card>
          <CardContent>
            <Activity
              assignment={assignment}
              variant="inProgress"
              onComplete={onComplete}
            />
          </CardContent>
        </Card>
      ) : (
        <div className="animate-pulse rounded-xl bg-slate-200 py-40 "></div>
      )}
    </div>
  )
}

export default CurrentActivity
