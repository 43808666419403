import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { graphql, useFragment } from 'react-relay'
import { PracticesCardEdgeFragment$key } from './__generated__/PracticesCardEdgeFragment.graphql'
import { PracticesCardLearnerFragment$key } from './__generated__/PracticesCardLearnerFragment.graphql'
import PracticeRating from '../PracticeRating'

const EdgeFragment = graphql`
  fragment PracticesCardEdgeFragment on LearnerInitiativeEdge {
    node {
      practices {
        nodes {
          id
        }
      }
    }
  }
`

const LearnerFragment = graphql`
  fragment PracticesCardLearnerFragment on Learner {
    learnerProfile {
      practiceScores {
        practice {
          id
          name
        }
        score
        managerScore
      }
    }
    practice {
      practice {
        id
      }
    }
  }
`

interface PracticesCardProps {
  edgeRef: PracticesCardEdgeFragment$key
  learner: PracticesCardLearnerFragment$key
  hidden: boolean
}

export function PracticesCard({
  edgeRef,
  learner,
  hidden,
}: PracticesCardProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const learnerData = useFragment(LearnerFragment, learner)
  const practiceScores = learnerData.learnerProfile.practiceScores
  const initiativePracticeIds = new Set(
    data.node.practices.nodes.map((node) => node.id)
  )
  const filteredPracticeScores = practiceScores
    .filter((score) => initiativePracticeIds.has(score.practice.id))
    .filter((score) => score.score !== null)

  const currentPracticeId = learnerData.practice?.practice.id

  if (filteredPracticeScores.length === 0) {
    return null
  }

  const managerScores = filteredPracticeScores.filter(
    (score) => score.managerScore !== null
  )

  return (
    <Card className={`col-span-3 ${hidden ? 'lg:hidden' : 'lg:block'}`}>
      <CardHeader>
        <CardTitle size="sm">Practices</CardTitle>
        <CardDescription>
          This initiative is designed to develop these workplace practices.
        </CardDescription>
      </CardHeader>
      <CardContent className="text-sm">
        <div className="space-y-6">
          <div className="space-y-1.5">
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-sm bg-flintBlue" />
              <div>Learner rating</div>
            </div>
            {managerScores.length > 0 && (
              <div className="flex items-center gap-2">
                <div className="h-3 w-3 rounded-sm bg-flintOrange" />
                <div>Manager rating</div>
              </div>
            )}
          </div>
          {filteredPracticeScores.map((practice) => (
            <div
              key={practice.practice.id}
              className={`space-y-3 ${
                practice.practice.id === currentPracticeId
                  ? 'border-l-4 border-slate-500 pl-3'
                  : 'pl-[19px]'
              }`}
            >
              <div className="space-y-1">
                {practice.practice.id === currentPracticeId ? (
                  <h3 className="text-xs opacity-60">Current practice</h3>
                ) : null}
                <h3 className="text-xs font-semibold uppercase tracking-widest opacity-60">
                  {practice.practice.name}
                </h3>
              </div>
              <PracticeRating
                self={practice.score}
                manager={practice.managerScore}
                showLabel={false}
              />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
