/**
 * @generated SignedSource<<a3c5b94d8316e4d17beb8abc286c9e6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuestionCardFragment$data = {
  readonly choice: number | null;
  readonly choices: ReadonlyArray<number> | null;
  readonly id: string;
  readonly ordinal: number;
  readonly previousChoice: number | null;
  readonly question: {
    readonly choiceCount: number;
    readonly choiceLabels: ReadonlyArray<string> | null;
    readonly id: string;
    readonly lastIsNoneOfTheAbove: boolean;
    readonly multipleResponses: boolean;
  };
  readonly text: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"PromptAnswerFragment">;
  readonly " $fragmentType": "QuestionCardFragment";
};
export type QuestionCardFragment$key = {
  readonly " $data"?: QuestionCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionCardFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionCardFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromptAnswerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyQuestion",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "choiceCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "choiceLabels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "multipleResponses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastIsNoneOfTheAbove",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "choice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "choices",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previousChoice",
      "storageKey": null
    }
  ],
  "type": "SurveyAnswer",
  "abstractKey": null
};
})();

(node as any).hash = "ad54abb2cb656e238828798960cd008d";

export default node;
