/**
 * @generated SignedSource<<b9223a3cf803d055750d67f42af25de4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PracticesCardEdgeFragment$data = {
  readonly node: {
    readonly practices: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  };
  readonly " $fragmentType": "PracticesCardEdgeFragment";
};
export type PracticesCardEdgeFragment$key = {
  readonly " $data"?: PracticesCardEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PracticesCardEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PracticesCardEdgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PracticeConnection",
          "kind": "LinkedField",
          "name": "practices",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Practice",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "2e1f7df936d77dfb2e3440b08ac49a01";

export default node;
