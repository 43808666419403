/**
 * @generated SignedSource<<06c8f75a2f00dfee242347744abd3e2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type goalProgressHookEdgeFragment$data = {
  readonly completedAssignments: {
    readonly totalCount: number;
  };
  readonly initiative: {
    readonly goalActivityCount: number | null;
  };
  readonly " $fragmentType": "goalProgressHookEdgeFragment";
};
export type goalProgressHookEdgeFragment$key = {
  readonly " $data"?: goalProgressHookEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"goalProgressHookEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "goalProgressHookEdgeFragment",
  "selections": [
    {
      "alias": "initiative",
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goalActivityCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "completedAssignments",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "feedbackCompleted": true
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "assignments(filter:{\"feedbackCompleted\":true})"
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "7014032498ff4bb7a377fd1c84d5d54e";

export default node;
