/**
 * @generated SignedSource<<cda9eedb0aead6a5b8bbb20fac3583d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type QuestionCardAnswerMutation$variables = {
  answer: string;
  choice?: number | null;
  choices?: ReadonlyArray<number> | null;
  text?: string | null;
};
export type QuestionCardAnswerMutation$data = {
  readonly setSurveyAnswer: {
    readonly choice: number | null;
    readonly choices: ReadonlyArray<number> | null;
    readonly id: string;
    readonly text: string | null;
  } | null;
};
export type QuestionCardAnswerMutation = {
  response: QuestionCardAnswerMutation$data;
  variables: QuestionCardAnswerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "answer"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "choice"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "choices"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "answer",
        "variableName": "answer"
      },
      {
        "kind": "Variable",
        "name": "choice",
        "variableName": "choice"
      },
      {
        "kind": "Variable",
        "name": "choices",
        "variableName": "choices"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "SurveyAnswer",
    "kind": "LinkedField",
    "name": "setSurveyAnswer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "choice",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "choices",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionCardAnswerMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QuestionCardAnswerMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "c20a5c1f80c04bfdbc7bcd2589440d8f",
    "id": null,
    "metadata": {},
    "name": "QuestionCardAnswerMutation",
    "operationKind": "mutation",
    "text": "mutation QuestionCardAnswerMutation(\n  $answer: ID!\n  $choice: Int\n  $text: String\n  $choices: [Int!]\n) {\n  setSurveyAnswer(answer: $answer, choice: $choice, text: $text, choices: $choices) {\n    id\n    choice\n    choices\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "5a94ad53fb9b49a813ecc807a6ca8bf2";

export default node;
