/**
 * @generated SignedSource<<46f8723d6338b5e91c38659e8320a5b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyLearner_learner$data = {
  readonly customer: {
    readonly name: string;
  };
  readonly email: string | null;
  readonly fullName: string | null;
  readonly id: string;
  readonly initiatives: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly showChoices: boolean;
    }>;
  };
  readonly lmsUserID: string;
  readonly personalityTest: {
    readonly " $fragmentSpreads": FragmentRefs<"PersonalityTestResult_result">;
  } | null;
  readonly surveyAwaitingResponseFromManager: {
    readonly id: string;
  } | null;
  readonly treatments: ReadonlyArray<{
    readonly experiment: {
      readonly name: string;
    };
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeSelectorLearnerFragment" | "LearningProfile_learner" | "MyLearnerSurveyFragment">;
  readonly " $fragmentType": "MyLearner_learner";
};
export type MyLearner_learner$key = {
  readonly " $data"?: MyLearner_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyLearner_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyLearner_learner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeSelectorLearnerFragment"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lmsUserID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showChoices",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalityTest",
      "kind": "LinkedField",
      "name": "personalityTest",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PersonalityTestResult_result"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponseFromManager",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Treatment",
      "kind": "LinkedField",
      "name": "treatments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Experiment",
          "kind": "LinkedField",
          "name": "experiment",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearningProfile_learner"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MyLearnerSurveyFragment"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "7699e2aa1eae826dfdae2cb56b1b363c";

export default node;
