/**
 * @generated SignedSource<<fa43d06e7573375a06c4bbce0d0d219f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyLearnerSurveyCreateResponseMutation$variables = {
  learner: string;
  surveyAssignment: string;
};
export type MyLearnerSurveyCreateResponseMutation$data = {
  readonly createSurveyResponseFromManager: {
    readonly id: string;
    readonly learnerDescribed: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"MyLearnerSurveyFragment">;
    } | null;
  } | null;
};
export type MyLearnerSurveyCreateResponseMutation = {
  response: MyLearnerSurveyCreateResponseMutation$data;
  variables: MyLearnerSurveyCreateResponseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "learner"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "surveyAssignment"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "learner",
    "variableName": "learner"
  },
  {
    "kind": "Variable",
    "name": "surveyAssignment",
    "variableName": "surveyAssignment"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "longDescription",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyLearnerSurveyCreateResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "createSurveyResponseFromManager",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learnerDescribed",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MyLearnerSurveyFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyLearnerSurveyCreateResponseMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SurveyResponse",
        "kind": "LinkedField",
        "name": "createSurveyResponseFromManager",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Learner",
            "kind": "LinkedField",
            "name": "learnerDescribed",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lmsUserID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyResponse",
                "kind": "LinkedField",
                "name": "pendingSurveyFromManager",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Survey",
                    "kind": "LinkedField",
                    "name": "survey",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "instructions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SurveyResponseSection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SurveyAnswer",
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SurveyQuestion",
                            "kind": "LinkedField",
                            "name": "question",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prompt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Skill",
                                "kind": "LinkedField",
                                "name": "behavior",
                                "plural": false,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Practice",
                                "kind": "LinkedField",
                                "name": "practices",
                                "plural": true,
                                "selections": (v3/*: any*/),
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "choiceLabels",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "multipleResponses",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastIsNoneOfTheAbove",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "choices",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ordinal",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "previousChoice",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SurveyAssignment",
                "kind": "LinkedField",
                "name": "surveyAwaitingResponseFromManager",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a29741662a736647b8a9fe2f9ab7fe6",
    "id": null,
    "metadata": {},
    "name": "MyLearnerSurveyCreateResponseMutation",
    "operationKind": "mutation",
    "text": "mutation MyLearnerSurveyCreateResponseMutation(\n  $learner: ID!\n  $surveyAssignment: ID!\n) {\n  createSurveyResponseFromManager(learner: $learner, surveyAssignment: $surveyAssignment) {\n    id\n    learnerDescribed {\n      id\n      ...MyLearnerSurveyFragment\n    }\n  }\n}\n\nfragment MyLearnerSurveyFragment on Learner {\n  id\n  ...TemplateLearnerFragment\n  ...QuestionCardLearnerFragment\n  pendingSurveyFromManager {\n    id\n    endDate\n    survey {\n      id\n      instructions\n      title\n    }\n    sections {\n      id\n      answers {\n        id\n        ...QuestionCardFragment\n      }\n    }\n    ...completedAnswersFragment\n  }\n  surveyAwaitingResponseFromManager {\n    id\n  }\n}\n\nfragment PromptAnswerFragment on SurveyAnswer {\n  question {\n    prompt\n    behavior {\n      id\n      name\n      longDescription\n    }\n    practices {\n      id\n      name\n      longDescription\n    }\n    id\n  }\n}\n\nfragment PromptLearnerFragment on Learner {\n  ...TemplateLearnerFragment\n}\n\nfragment QuestionCardFragment on SurveyAnswer {\n  id\n  ...PromptAnswerFragment\n  question {\n    id\n    choiceCount\n    choiceLabels\n    multipleResponses\n    lastIsNoneOfTheAbove\n  }\n  choice\n  choices\n  text\n  ordinal\n  previousChoice\n}\n\nfragment QuestionCardLearnerFragment on Learner {\n  ...PromptLearnerFragment\n}\n\nfragment TemplateLearnerFragment on Learner {\n  email\n  fullName\n  lmsUserID\n}\n\nfragment completedAnswersFragment on SurveyResponse {\n  sections {\n    answers {\n      id\n      question {\n        choiceCount\n        id\n      }\n      choice\n      choices\n      text\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "006f017f45aa1bfec70d46fdffe8f1e0";

export default node;
