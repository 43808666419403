/**
 * @generated SignedSource<<a17613e7603c5a885796d0c81ed293ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentActivity_learner$data = {
  readonly currentAssignment: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ActivityFragment">;
  } | null;
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly practices: {
          readonly totalCount: number;
        };
      };
      readonly " $fragmentSpreads": FragmentRefs<"InitiativeStatusEdgeFragment">;
    }>;
  };
  readonly practice: {
    readonly id: string;
    readonly nextActivity: {
      readonly id: string;
    } | null;
    readonly nextPractice: {
      readonly id: string;
    } | null;
    readonly practice: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
  readonly readyForAssignments: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeStatusLearnerFragment">;
  readonly " $fragmentType": "CurrentActivity_learner";
};
export type CurrentActivity_learner$key = {
  readonly " $data"?: CurrentActivity_learner$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentActivity_learner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentActivity_learner",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeStatusLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readyForAssignments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerPractice",
      "kind": "LinkedField",
      "name": "practice",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Activity",
          "kind": "LinkedField",
          "name": "nextActivity",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "nextPractice",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Initiative",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PracticeConnection",
                  "kind": "LinkedField",
                  "name": "practices",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InitiativeStatusEdgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "bb8913f3092a5d8d81af56b77ac06044";

export default node;
