import { Button } from '@/components/Button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import { Hero, HeroSubtitle, HeroTitle } from '@/components/lms/Hero'
import Pill from '@/components/lms/Pill'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/Tooltip'
import Markdown from '@/components/Markdown'
import { SelectInitiativeFragment$key } from './__generated__/SelectInitiativeFragment.graphql'
import { BarChartBig, MessageCircleQuestion } from 'lucide-react'
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay'
import { SelectInitiativeQuery } from './__generated__/SelectInitiativeQuery.graphql'
import { useNavigate } from '@tanstack/react-router'
import { SelectInitiativeMutation } from './__generated__/SelectInitiativeMutation.graphql'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const Query = graphql`
  query SelectInitiativeQuery {
    learner {
      ...AppContainer_learner
      initiatives(membershipFilter: { atDate: 0 }) {
        nodes {
          id
          description
          showChoices
          choices {
            nodes {
              id
              ...SelectInitiativeFragment
            }
          }
        }
      }
    }
  }
`

const SelectInitiativeFragment = graphql`
  fragment SelectInitiativeFragment on Initiative {
    id
    name
    description
    behaviors {
      nodes {
        id
        name
        shortDescription
        longDescription
      }
    }
    practices {
      nodes {
        id
        name
        shortDescription
        longDescription
      }
    }
  }
`

const Mutation = graphql`
  mutation SelectInitiativeMutation($initiative: ID!) {
    chooseInitiative(initiative: $initiative) {
      id
      initiatives {
        nodes {
          id
          choices {
            # Relay unhelpfully tries to rerender the SelectInitiative
            # component with the new initiative so it needs this extra
            # field to know how to do that before immediately redirecting
            nodes {
              id
            }
          }
          showChoices
        }
      }
    }
  }
`

type ItemListProps = {
  title: string
  items: readonly {
    id: string
    name: string
    shortDescription: string
    longDescription: string
  }[]
}

function ItemList({ title, items }: ItemListProps) {
  if (items.length === 0) return null

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <BarChartBig className="w-4 stroke-flintBlue" strokeWidth={2.5} />
        <h3 className="font-semibold">{title}</h3>
      </div>
      <div className="flex flex-wrap gap-2">
        {items.map((item) => (
          <Dialog key={item.name}>
            <DialogContent size="md" closeBtn>
              <DialogHeader>
                <DialogTitle>{item.name}</DialogTitle>
              </DialogHeader>
              <Markdown>{item.longDescription}</Markdown>
              <DialogFooter>
                <DialogClose asChild>
                  <Button size="sm">Close</Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>

            <TooltipProvider delayDuration={150}>
              <Tooltip>
                <DialogTrigger asChild>
                  <TooltipTrigger>
                    <div className="cursor-pointer">
                      <Pill>{item.name}</Pill>
                    </div>
                  </TooltipTrigger>
                </DialogTrigger>
                <TooltipContent>{item.shortDescription}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Dialog>
        ))}
      </div>
    </div>
  )
}

type InitiativeCardProps = {
  initiativeRef: SelectInitiativeFragment$key
  selectInitiative: (initiativeID: string) => void
  isLoading: boolean
}

function InitiativeCard({
  initiativeRef,
  selectInitiative,
  isLoading,
}: InitiativeCardProps) {
  const initiative = useFragment(SelectInitiativeFragment, initiativeRef)
  return (
    <Card key={initiative.name}>
      <CardHeader>
        <CardTitle size="sm">{initiative.name}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <MessageCircleQuestion
              className="w-4 stroke-flintBlue"
              strokeWidth={2.5}
            />
            <h2 className="font-semibold">Description</h2>
          </div>
          <Markdown>{initiative.description}</Markdown>
        </div>

        <ItemList
          title="Improve These Behaviors"
          items={initiative.behaviors.nodes}
        />

        <ItemList
          title="Develop These Practices"
          items={initiative.practices.nodes}
        />

        <div className="pt-12">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full">Choose This Initiative</Button>
            </DialogTrigger>
            <DialogContent aria-describedby={undefined}>
              <DialogHeader>
                <DialogTitle>Great Choice!</DialogTitle>
              </DialogHeader>
              <p>
                <span data-isolate>You&apos;ve selected: </span>
                <span className="font-semibold text-flintBlue">
                  {initiative.name}
                </span>
              </p>
              <p>
                We hope you&apos;re excited to get started. Once you confirm
                your choice, you cannot change it.
              </p>
              <DialogFooter>
                <Button
                  onClick={() => selectInitiative(initiative.id)}
                  disabled={isLoading}
                >
                  Confirm
                </Button>
                <DialogClose asChild>
                  <Button variant="secondary" disabled={isLoading}>
                    Cancel
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </CardContent>
    </Card>
  )
}

export default function SelectInitiative() {
  const data = useLazyLoadQuery<SelectInitiativeQuery>(Query, {})
  const navigate = useNavigate()

  const [selectInitiative, isLoading] =
    useMutation<SelectInitiativeMutation>(Mutation)

  const handleSelectInitiative = (initiativeID: string) => {
    selectInitiative({
      variables: {
        initiative: initiativeID,
      },
      onCompleted: () => {
        navigate({ to: '/lms' })
      },
    })
  }
  const initiatives = data.learner?.initiatives.nodes
  const initiative =
    initiatives && initiatives.length > 0 ? initiatives[0] : undefined

  const choices = initiative?.choices.nodes
  if (!choices || !initiative?.showChoices || choices.length === 0) {
    navigate({ to: '/lms' })
    return null
  }

  return (
    <AppContainer page="initiatives" learner={data.learner}>
      <Hero>
        <HeroTitle>Select Initiative</HeroTitle>
        <HeroSubtitle>{initiative.description}</HeroSubtitle>
      </Hero>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {choices.map((choice) => (
          <InitiativeCard
            key={choice.id}
            initiativeRef={choice}
            selectInitiative={handleSelectInitiative}
            isLoading={isLoading}
          />
        ))}
      </div>
    </AppContainer>
  )
}
