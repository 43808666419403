/**
 * @generated SignedSource<<5af4f411a12ec49ede88ee7d295486b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrintActivityQuery$variables = {
  assignmentID: string;
};
export type PrintActivityQuery$data = {
  readonly node: {
    readonly completionDate?: number | null;
    readonly learner?: {
      readonly locale: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ActivityFragment">;
  } | null;
};
export type PrintActivityQuery = {
  response: PrintActivityQuery$data;
  variables: PrintActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assignmentID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "assignmentID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completionDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrintActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "learner",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityFragment"
              }
            ],
            "type": "Assignment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrintActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Learner",
                "kind": "LinkedField",
                "name": "learner",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Assignment",
                    "kind": "LinkedField",
                    "name": "currentAssignment",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnerPractice",
                    "kind": "LinkedField",
                    "name": "practice",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Activity",
                        "kind": "LinkedField",
                        "name": "nextActivity",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Activity",
                        "kind": "LinkedField",
                        "name": "easierActivity",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Activity",
                        "kind": "LinkedField",
                        "name": "harderActivity",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "activity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instructions",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guidance",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eligibleForAssignment",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Initiative",
                "kind": "LinkedField",
                "name": "initiative",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Assignment",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e43c52e0a2b4f41ccd5ea09bb71c096c",
    "id": null,
    "metadata": {},
    "name": "PrintActivityQuery",
    "operationKind": "query",
    "text": "query PrintActivityQuery(\n  $assignmentID: ID!\n) {\n  node(id: $assignmentID) {\n    __typename\n    ... on Assignment {\n      learner {\n        locale\n        id\n      }\n      completionDate\n      ...ActivityFragment\n    }\n    id\n  }\n}\n\nfragment ActivityFragment on Assignment {\n  id\n  ...AddToCalendarFragment\n  learner {\n    currentAssignment {\n      id\n    }\n    practice {\n      difficulty\n      id\n      nextActivity {\n        id\n      }\n      easierActivity {\n        id\n      }\n      harderActivity {\n        id\n      }\n    }\n    id\n  }\n  activity {\n    id\n    name\n    difficulty\n    shortDescription\n    instructions\n    guidance\n    eligibleForAssignment\n  }\n  notes\n  initiative {\n    id\n  }\n}\n\nfragment AddToCalendarFragment on Assignment {\n  id\n  activity {\n    name\n    shortDescription\n    instructions\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "db035257f29ffdc747238478b8e9adbe";

export default node;
