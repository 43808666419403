/**
 * @generated SignedSource<<332a891f37f94db433d52d216ebe6ead>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeSummaryCardFragment$data = {
  readonly endDate: number | null;
  readonly initiative: {
    readonly description: string;
    readonly name: string;
  };
  readonly startDate: number;
  readonly " $fragmentType": "InitiativeSummaryCardFragment";
};
export type InitiativeSummaryCardFragment$key = {
  readonly " $data"?: InitiativeSummaryCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeSummaryCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeSummaryCardFragment",
  "selections": [
    {
      "alias": "initiative",
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "5dbd0d86414f420c9d61ee3accd0233e";

export default node;
