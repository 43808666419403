/**
 * @generated SignedSource<<683efdae42099a1b4821d6e3db32f9de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeStatusLearnerFragment$data = {
  readonly currentAssignment: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly practice: {
    readonly difficulty: number;
    readonly easierActivity: {
      readonly id: string;
    } | null;
    readonly harderActivity: {
      readonly id: string;
    } | null;
    readonly id: string;
    readonly managerScore: number | null;
    readonly nextPractice: {
      readonly id: string;
    } | null;
    readonly practice: {
      readonly id: string;
      readonly name: string;
    };
    readonly score: number | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"goalProgressHookLearnerFragment">;
  readonly " $fragmentType": "InitiativeStatusLearnerFragment";
};
export type InitiativeStatusLearnerFragment$key = {
  readonly " $data"?: InitiativeStatusLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeStatusLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeStatusLearnerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goalProgressHookLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Assignment",
      "kind": "LinkedField",
      "name": "currentAssignment",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerPractice",
      "kind": "LinkedField",
      "name": "practice",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "difficulty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "practice",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "nextPractice",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Activity",
          "kind": "LinkedField",
          "name": "easierActivity",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Activity",
          "kind": "LinkedField",
          "name": "harderActivity",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "managerScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "f4811f981a1cbfff733549782bb40d92";

export default node;
