import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { graphql, useMutation } from 'react-relay'
const NotateAssignmentMutation = graphql`
  mutation ActivityNotesMutation($assignmentID: ID!, $notes: String!) {
    notateAssignment(assignment: $assignmentID, notes: $notes) {
      id
      notes
    }
  }
`

const MAX_NOTES_LENGTH = 10000 // Claude says this is like 1600 words, which should be plenty

type NotesProps = {
  assignmentID: string
  notes: string
}

function Notes({ assignmentID, notes }: NotesProps) {
  const [noteState, setNoteState] = useState(notes || '')
  const [notateAssignment] = useMutation(NotateAssignmentMutation)

  const updateNotes = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= MAX_NOTES_LENGTH) {
      setNoteState(e.target.value)
    }
  }

  const saveNotes = useCallback(async () => {
    if (notes !== noteState) {
      notateAssignment({ variables: { assignmentID, notes: noteState } })
    }
  }, [assignmentID, notateAssignment, notes, noteState])

  useEffect(() => {
    const interval = setInterval(async () => {
      saveNotes()
    }, 3000)
    return () => clearInterval(interval)
  }, [saveNotes])

  return (
    <textarea
      value={noteState}
      onChange={updateNotes}
      onBlur={saveNotes}
      maxLength={MAX_NOTES_LENGTH}
      className="min-h-[4rem] w-full rounded-lg bg-white px-2 py-2 text-sm shadow-sm outline-flintOrange ring-1 ring-slate-900/5"
    />
  )
}

export default Notes
