import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import { Link, useSearch } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { Button } from '@/components/Button'
import { Mail } from 'lucide-react'

function EmailLinkProcessing() {
  const search = useSearch({ from: '/reports/login' }) // TODO: pass in?
  const [email, setEmail] = useState('')
  const [emailLinkOpen, setEmailLinkOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const error = '' // Leaving this here in case we need it later

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  useEffect(() => {
    completePasswordlessSignIn()
  })

  const EMAIL_LOCAL_STORAGE_KEY = 'emailForSignIn'

  const completePasswordlessSignIn = async () => {
    const auth = getAuth()
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email =
        window.localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY) ||
        window.prompt('Please provide your email for confirmation')
      if (!email) {
        return
      }
      window.localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email)

      await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem(EMAIL_LOCAL_STORAGE_KEY)
      window.location.href = search.redirect || '/reports'
    }
  }

  const handleEmailLoginSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    setSubmitting(true)

    const auth = getAuth()
    await sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true,
    })
    setSubmitting(false)
    window.localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email)
    setSuccess(true)
  }
  return emailLinkOpen ? (
    <form className="space-y-4" onSubmit={handleEmailLoginSubmit}>
      <input
        type="email"
        name="email"
        autoComplete="email"
        required
        placeholder="Enter your email"
        className={`block w-full rounded-md border border-slate-900/10 p-2.5 text-sm focus:ring-2 focus:ring-inset focus:ring-flintBlue sm:text-sm sm:leading-6 ${
          error ? 'ring-red-500' : ''
        }`}
        onChange={handleEmailChange}
        value={email}
      />
      <div className="flex gap-4">
        <Button
          type="submit"
          variant={submitting ? 'loading' : 'default'}
          disabled={success || submitting}
          className="flex-grow"
        >
          Get sign in link
        </Button>
        <Button onClick={() => setEmailLinkOpen(false)} variant="secondary">
          Cancel
        </Button>
      </div>
      {success ? (
        <div className="flex flex-col items-center gap-4 text-center text-sm text-green-500">
          <p>
            Thanks! If this email is in our system, we will email a sign in
            link.
          </p>
          <p>
            Please contact support@flintls.com if you have any issues accessing
            your account.
          </p>
        </div>
      ) : null}
    </form>
  ) : (
    <button
      className="relative w-full rounded-md border border-slate-900/10 py-2.5 text-sm duration-200 hover:bg-slate-100"
      onClick={() => setEmailLinkOpen(true)}
    >
      <Mail className="absolute left-4 top-1/2 w-5 -translate-y-1/2 transform" />
      Sign in with email
    </button>
  )
}

function Login() {
  const search = useSearch({ from: '/reports/login' })

  const doGoogleLogin = async () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('email')
    await signInWithPopup(auth, provider)
    window.location.href = search.redirect || '/reports'
  }

  return (
    <>
      <div className="absolute flex h-full w-full items-center justify-center">
        <div className="contain">
          <div className="flex w-full max-w-5xl overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-slate-900/5 lg:h-[550px]">
            <div className="relative hidden w-3/5 items-center justify-end bg-slate-100 lg:flex">
              <div className="absolute flex h-full w-full items-center bg-gradient-to-l from-slate-50 to-transparent" />
              <img
                src="/reporting-app.png"
                alt=""
                className="w-[90%] object-cover object-right"
              />
            </div>
            <div className="flex items-center justify-center px-8 py-12 sm:p-12 lg:w-2/5">
              <div className="space-y-3 text-center">
                <img
                  src="/logo.svg"
                  alt="Flint Logo"
                  className="mx-auto w-20"
                />
                <h1 className="font-semibold">Reporting dashboard</h1>
                <p className="text-sm">
                  Select an option below to sign in to the Reporting Dashboard.
                </p>
                <div className="space-y-4 py-2">
                  <button
                    onClick={doGoogleLogin}
                    className="relative w-full rounded-md border border-slate-900/10 py-2.5 text-sm duration-200 hover:bg-slate-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      className="absolute left-4 top-1/2 w-5 -translate-y-1/2 transform"
                    >
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        fill="#EA4335"
                      />
                      <path d="M1 1h22v22H1z" fill="none" />
                    </svg>
                    Sign in with Google
                  </button>
                  <EmailLinkProcessing />
                </div>
                <p className="text-sm opacity-60">
                  If you need to log in to your learner account, please{' '}
                  <Link
                    to="/lms/login"
                    className="underline decoration-slate-600/30 underline-offset-2 duration-300 hover:decoration-slate-600/100"
                  >
                    click here
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
