/**
 * @generated SignedSource<<5893bdd9aafc157e21ec0afeef5c77db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromptAnswerFragment$data = {
  readonly question: {
    readonly behavior: {
      readonly id: string;
      readonly longDescription: string;
      readonly name: string;
    } | null;
    readonly practices: ReadonlyArray<{
      readonly id: string;
      readonly longDescription: string;
      readonly name: string;
    }>;
    readonly prompt: string;
  };
  readonly " $fragmentType": "PromptAnswerFragment";
};
export type PromptAnswerFragment$key = {
  readonly " $data"?: PromptAnswerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromptAnswerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "longDescription",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromptAnswerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyQuestion",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prompt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Skill",
          "kind": "LinkedField",
          "name": "behavior",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Practice",
          "kind": "LinkedField",
          "name": "practices",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SurveyAnswer",
  "abstractKey": null
};
})();

(node as any).hash = "1a8ce30b29ae6646f0b9c74885e5d056";

export default node;
