import Initiative from '@/components/lms/Initiatives/Initiative'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { PastInitiativesQuery } from './__generated__/PastInitiativesQuery.graphql'
import { InitiativeLearnerFragment$key } from '@/components/lms/Initiatives/__generated__/InitiativeLearnerFragment.graphql'
import { InitiativeEdgeFragment$key } from '@/components/lms/Initiatives/__generated__/InitiativeEdgeFragment.graphql'
import { Navigate } from '@tanstack/react-router'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select'
import { truncateString } from '@/common/utils'
import { useState } from 'react'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const Query = graphql`
  query PastInitiativesQuery {
    learner {
      ...AppContainer_learner
      ...InitiativeLearnerFragment
      pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
        edges {
          ...InitiativeEdgeFragment
          cursor
          initiative: node {
            id
            name
            showChoices
          }
          startDate
        }
      }
    }
  }
`

type PastInitiativesComponentProps = {
  learnerRef: InitiativeLearnerFragment$key
  edgeRef: InitiativeEdgeFragment$key
  choices: {
    id: string
    name: string
  }[]
  selectedName: string
  handlePastInitiativeChange: (value: string) => void
}
export function PastInitiativesComponent({
  learnerRef,
  edgeRef,
  choices,
  selectedName,
  handlePastInitiativeChange,
}: PastInitiativesComponentProps) {
  return (
    <>
      <Hero>
        <HeroTitle>Past Initiatives</HeroTitle>
      </Hero>
      <>
        <div className="w-72">
          <Select onValueChange={handlePastInitiativeChange}>
            <SelectTrigger>
              <SelectValue placeholder={truncateString(selectedName, 28)} />
            </SelectTrigger>
            <SelectContent>
              {choices.map((initiative) => (
                <SelectItem key={initiative.id} value={initiative.id}>
                  {truncateString(initiative.name, 28)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Initiative
          edgeRef={edgeRef}
          learnerRef={learnerRef}
          learnerIsCurrent
          showInteractivity={false}
        />
      </>
    </>
  )
}

export function PastInitiatives() {
  const data = useLazyLoadQuery<PastInitiativesQuery>(Query, {})
  const learner = data.learner
  const pastInitiatives = (
    learner?.pastInitiatives?.edges.filter(
      (initiative) => !initiative.initiative.showChoices
    ) || []
  ).sort((a, b) => b.startDate - a.startDate)

  const [selected, setSelected] = useState(
    pastInitiatives.length > 0 ? pastInitiatives[0].cursor : ''
  )

  const selectedInitiative = pastInitiatives.find(
    (initiative) => initiative.cursor === selected
  )

  if (!learner || !selectedInitiative || pastInitiatives.length === 0)
    return <Navigate to="/lms" />

  return (
    <AppContainer page="past-initiatives" learner={learner}>
      <PastInitiativesComponent
        learnerRef={learner}
        edgeRef={selectedInitiative}
        choices={pastInitiatives.map((initiative) => ({
          id: initiative.cursor,
          name: initiative.initiative.name,
        }))}
        selectedName={selectedInitiative.initiative.name}
        handlePastInitiativeChange={setSelected}
      />
    </AppContainer>
  )
}
