import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import Markdown from '@/components/Markdown'
import { Navigate } from '@tanstack/react-router'
import { ArrowDown, CheckCircle2, LockKeyhole, RefreshCw } from 'lucide-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import type {
  PathwayQuery,
  PathwayQuery$data,
} from './__generated__/PathwayQuery.graphql'
import { Fragment } from 'react'
import { Hero, HeroSubtitle, HeroTitle } from '@/components/lms/Hero'

type Step = NonNullable<
  NonNullable<PathwayQuery$data['learner']>['currentPathway']
>['pathway']['steps'][number]

const Query = graphql`
  query PathwayQuery {
    learner {
      ...AppContainer_learner
      id
      currentPathway {
        id
        pathway {
          id
          name
          objective
          description
          steps {
            id
            title
            ordinal
            shortDescription
            longDescription
          }
          visible
        }
        stepsCompleted
      }
    }
  }
`

type StepProps = {
  step: Step
  stepsCompleted: number
}

function Step({ step, stepsCompleted }: StepProps) {
  const isCurrent = step.ordinal === stepsCompleted
  return (
    <Card
      className={`relative w-full duration-300 ${
        !isCurrent && 'opacity-75 hover:opacity-100'
      } ${isCurrent && 'ring-1 ring-flintOrange'}`}
    >
      <CardHeader>
        <CardTitle size="sm">
          Step {step.ordinal + 1}: {step.title}
        </CardTitle>
        <CardDescription className="space-y-2">
          <h2 className="m font-semibold">This step will help you...</h2>
          <div>{step.shortDescription}</div>
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-2 text-sm">
        <div className="space-y-2">
          <h2 className="font-semibold">Your activity will prompt you to...</h2>
          <Markdown>{step.longDescription}</Markdown>
        </div>
      </CardContent>
      <div
        className={`flex items-center justify-end gap-1 pb-3 pr-4 text-sm font-medium text-flintBlue lg:absolute lg:right-4 lg:top-3 lg:pb-0 lg:pr-0 ${
          isCurrent && 'animate-pulse'
        }`}
      >
        {step.ordinal < stepsCompleted && (
          <>
            <p>Complete</p>
            <CheckCircle2 className="h-6 w-6 fill-flintBlue stroke-white" />
          </>
        )}
        {isCurrent ? (
          <>
            <p>In progress</p>
            <RefreshCw className="h-4 w-4 animate-[spin_6s_linear_infinite] stroke-flintBlue" />
          </>
        ) : null}
        {step.ordinal > stepsCompleted && (
          <LockKeyhole className="m-3 h-4 w-4 stroke-flintBlue" />
        )}
      </div>
    </Card>
  )
}

function Pathway() {
  const data = useLazyLoadQuery<PathwayQuery>(Query, {})
  const stepsCompleted = data.learner?.currentPathway?.stepsCompleted || 0
  const pathway = data.learner?.currentPathway?.pathway
  const steps = pathway?.steps || []
  const progressBarWidth = (100 * stepsCompleted) / steps.length

  /* FIXME: Re-think this? Right now it seems like a bad idea, because it
   * scrolls the user right past all the explanatory copy.
  useEffect(() => {
    if (currentStepRef.current) {
      currentStepRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [])
  */

  if (!pathway || !pathway.visible) {
    return <Navigate to="/lms" replace={true} from="/lms/pathway" />
  }

  return (
    <AppContainer page="pathway" learner={data.learner}>
      <Hero>
        <HeroTitle>Your Activity Pathway</HeroTitle>
        <HeroSubtitle>
          A pathway guides you through a series of steps to help you work on a
          specific behavior. At each step, you will be presented with activity
          options based on several factors, such as your personality, role, and
          development needs. To complete the pathway, complete one activity at
          each step.
        </HeroSubtitle>
      </Hero>
      <div className="grid gap-6 sm:grid-cols-2">
        <div className="order-last flex flex-col items-center gap-6 sm:order-first">
          {steps.map((step) => {
            return (
              <Fragment key={step.id}>
                {step.ordinal > 0 && <ArrowDown className="opacity-50" />}
                <Step step={step} stepsCompleted={stepsCompleted} />
              </Fragment>
            )
          })}
        </div>
        <div>
          <Card className="space-y-6 divide-y">
            <CardHeader>
              <p className="text-sm">Pathway</p>
              <div className="space-y-4 pb-2">
                <CardTitle>{pathway.name}</CardTitle>
                <div className="relative h-3">
                  <div className="absolute h-full w-full rounded-full bg-slate-200"></div>
                  <div
                    className="absolute h-full rounded-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
                    style={{ width: `${progressBarWidth}%` }}
                  ></div>
                  <div className="relative top-4 text-right text-xs">
                    {stepsCompleted} / {steps.length}
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-6 text-sm">
              <div className="space-y-2">
                <h2 className="font-semibold">Objective</h2>
                <p>{pathway.objective}</p>
              </div>
              <div className="space-y-2">
                <h2 className="font-semibold">Description</h2>
                <Markdown>{pathway.description}</Markdown>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="h-px bg-slate-900/5 sm:hidden"></div>
      </div>
    </AppContainer>
  )
}

export default Pathway
