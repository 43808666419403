/**
 * @generated SignedSource<<d245c3b1edbb92cf461fdf01f52a874a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FirstTimeDialogEdgeFragment$data = {
  readonly endDate: number | null;
  readonly initiative: {
    readonly id: string;
    readonly introContent: string;
    readonly name: string;
  };
  readonly seenIntro: boolean;
  readonly startDate: number;
  readonly " $fragmentType": "FirstTimeDialogEdgeFragment";
};
export type FirstTimeDialogEdgeFragment$key = {
  readonly " $data"?: FirstTimeDialogEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FirstTimeDialogEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FirstTimeDialogEdgeFragment",
  "selections": [
    {
      "alias": "seenIntro",
      "args": [
        {
          "kind": "Literal",
          "name": "popupType",
          "value": "InitiativePopupIntro"
        }
      ],
      "kind": "ScalarField",
      "name": "seenPopup",
      "storageKey": "seenPopup(popupType:\"InitiativePopupIntro\")"
    },
    {
      "alias": "initiative",
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "introContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "52e741cee6c23fcff1c301b5c30d1dfd";

export default node;
