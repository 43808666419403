import { SidebarMenuButton, SidebarMenuItem } from '@/components/Sidebar'
import { useRouter } from '@tanstack/react-router'
import { LinkOptions } from '@tanstack/react-router'
import { Register } from '@tanstack/react-router'
import { RoutePaths } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'

type NavLinkProps = {
  to?: RoutePaths<Register['router']['routeTree']> // takes priority over linkOptions.to
  linkOptions?: LinkOptions<Register['router']>
  children: React.ReactNode
  forceHighlight?: boolean
  highlight: string[]
  notHighlight?: string[]
}

function NavLink({
  linkOptions,
  to,
  children,
  highlight,
  notHighlight,
  forceHighlight,
}: NavLinkProps) {
  const router = useRouter()
  const path = router.state.location.pathname
  // Check if any of the highlight paths match the start of the current path
  const isNotHighlight =
    notHighlight?.some((notHighlightPath) =>
      path.startsWith(notHighlightPath)
    ) || false
  const isHighlight =
    forceHighlight === true ||
    (!isNotHighlight &&
      highlight.some((highlightPath) => path.startsWith(highlightPath)))

  return (
    <SidebarMenuItem>
      <SidebarMenuButton asChild isActive={isHighlight} size="lg">
        <Link
          to={to}
          {...linkOptions}
          role="link"
          aria-roledescription="navigation link"
          //   aria-current={isHighlight ? 'page' : undefined}
          //   className={`${
          //     isHighlight ? 'bg-slate-100' : ''
          //   } p-6 font-medium hover:bg-slate-200`}
        >
          {children}
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  )
}

export default NavLink
