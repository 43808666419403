/**
 * @generated SignedSource<<79d662afec60c23f1059e2874389228c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BehaviorsCardEdgeFragment$data = {
  readonly node: {
    readonly behaviors: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  };
  readonly " $fragmentType": "BehaviorsCardEdgeFragment";
};
export type BehaviorsCardEdgeFragment$key = {
  readonly " $data"?: BehaviorsCardEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BehaviorsCardEdgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BehaviorsCardEdgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Initiative",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SkillConnection",
          "kind": "LinkedField",
          "name": "behaviors",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Skill",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnerInitiativeEdge",
  "abstractKey": null
};

(node as any).hash = "ae6e89e9c4b792c66f27dbe2639e1544";

export default node;
