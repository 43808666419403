/**
 * @generated SignedSource<<15268ca7c5089831a0cfa6d84ab0273c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BehaviorQuery$variables = {
  behavior: string;
};
export type BehaviorQuery$data = {
  readonly behavior: {
    readonly activities: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly competency: {
      readonly area: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly id: string;
      readonly name: string;
    } | null;
    readonly id: string;
    readonly libraries: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly longDescription: string;
    readonly name: string;
  } | null;
};
export type BehaviorQuery = {
  response: BehaviorQuery$data;
  variables: BehaviorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "behavior"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "behavior"
      }
    ],
    "concreteType": "Skill",
    "kind": "LinkedField",
    "name": "behavior",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longDescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ActivityConnection",
        "kind": "LinkedField",
        "name": "activities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Activity",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillSet",
        "kind": "LinkedField",
        "name": "competency",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Area",
            "kind": "LinkedField",
            "name": "area",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LibraryConnection",
        "kind": "LinkedField",
        "name": "libraries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Library",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BehaviorQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BehaviorQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a6b25688250bebde84d5a36a7827f59e",
    "id": null,
    "metadata": {},
    "name": "BehaviorQuery",
    "operationKind": "query",
    "text": "query BehaviorQuery(\n  $behavior: ID!\n) {\n  behavior(id: $behavior) {\n    id\n    name\n    longDescription\n    activities {\n      nodes {\n        id\n        name\n      }\n    }\n    competency {\n      id\n      name\n      area {\n        id\n        name\n      }\n    }\n    libraries {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ad6948dbc0d58a84e3989ed695c6a35";

export default node;
