import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/Tooltip'
import { debounce } from 'lodash'
import { Prompt } from './Prompt'
import { graphql, useFragment, useMutation } from 'react-relay'
import { QuestionCardFragment$key } from './__generated__/QuestionCardFragment.graphql'
import { QuestionCardLearnerFragment$key } from './__generated__/QuestionCardLearnerFragment.graphql'
import { toast } from 'sonner'
import {
  QuestionCardAnswerMutation,
  QuestionCardAnswerMutation$variables,
} from './__generated__/QuestionCardAnswerMutation.graphql'
import { ArrowRight } from 'lucide-react'
import { Button } from '@/components/Button'

const AnswerFragment = graphql`
  fragment QuestionCardFragment on SurveyAnswer {
    id
    ...PromptAnswerFragment
    question {
      id
      choiceCount
      choiceLabels
      multipleResponses
      lastIsNoneOfTheAbove
    }
    choice
    choices
    text
    ordinal
    previousChoice
  }
`

const LearnerFragment = graphql`
  fragment QuestionCardLearnerFragment on Learner {
    ...PromptLearnerFragment
  }
`

const AnswerMutation = graphql`
  mutation QuestionCardAnswerMutation(
    $answer: ID!
    $choice: Int
    $text: String
    $choices: [Int!]
  ) {
    setSurveyAnswer(
      answer: $answer
      choice: $choice
      text: $text
      choices: $choices
    ) {
      id
      choice
      choices
      text
    }
  }
`

type TextQuestionProps = {
  answer: string | null
  randomize: boolean
  setAnswer: (answer: string) => void
  showNextButton: boolean
  onNextButtonClicked: () => void
}
function TextQuestion({
  answer,
  randomize,
  setAnswer,
  showNextButton,
  onNextButtonClicked,
}: TextQuestionProps) {
  const [internalAnswer, setInternalAnswer] = useState(answer)
  const debouncedSetAnswer = useMemo(
    () => debounce(setAnswer, 300),
    [setAnswer]
  )

  useEffect(() => {
    if (randomize) {
      const text = Math.random().toString(36).substring(2)
      setInternalAnswer(text)
      setAnswer(text)
    }
  }, [answer, randomize, setAnswer])

  return (
    <div className="space-y-4">
      <textarea
        value={internalAnswer || ''}
        onChange={(e) => {
          setInternalAnswer(e.target.value)
          debouncedSetAnswer(e.target.value)
        }}
        className="min-h-[6rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-2 ring-slate-900/5"
      />
      {showNextButton ? (
        <div className="flex justify-end">
          <Button
            onClick={() => {
              onNextButtonClicked()
            }}
          >
            Next Question
            <ArrowRight size={16} />
          </Button>
        </div>
      ) : null}
    </div>
  )
}

function PreviousChoiceTooltip({
  active,
  children,
}: {
  active: boolean
  children: React.ReactNode
}) {
  if (active) {
    return (
      <TooltipProvider delayDuration={150}>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent className="-top-1.5">
            Previous selection
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  } else {
    return <>{children}</>
  }
}

type RadioQuestionProps = {
  question: {
    choiceLabels: readonly string[] | null
    choiceCount: number
  }
  previousAnswer: number | null
  answer: number | null
  setAnswer: (choice: number) => void
}

function RadioQuestion({
  answer,
  question: { choiceCount, choiceLabels },
  previousAnswer,
  setAnswer,
}: RadioQuestionProps) {
  const labels =
    choiceLabels ??
    [...Array(choiceCount)].map((_, n) =>
      (choiceCount == 11 ? n : n + 1).toString()
    )

  // Check if any label is longer than 3 characters
  const hasLongLabels = labels.some((label) => label.length > 3)

  return (
    <div
      className={
        'group mx-auto ' +
        (hasLongLabels
          ? 'flex flex-col space-y-2'
          : 'flex w-full items-center justify-between sm:justify-center sm:gap-6')
      }
    >
      {[...Array(choiceCount)].map((_, n) => (
        <PreviousChoiceTooltip key={n} active={previousAnswer === n}>
          <label
            className={
              'flex cursor-pointer items-center ' +
              (hasLongLabels
                ? 'w-full rounded-md p-2 text-sm font-semibold '
                : 'h-11 w-11 justify-center rounded-md text-sm font-semibold ') +
              (answer === n
                ? 'bg-flintBlue text-white'
                : 'bg-slate-200 opacity-50 hover:opacity-100' +
                  (previousAnswer === n ? ' ring-2 ring-flintBlue' : ''))
            }
          >
            <input
              type="radio"
              value={n.toString()}
              checked={answer === n}
              onChange={() => setAnswer(n)}
              className="sr-only"
            />
            <span className={hasLongLabels ? 'ml-3' : ''}>{labels[n]}</span>
          </label>
        </PreviousChoiceTooltip>
      ))}
    </div>
  )
}

type CheckboxQuestionProps = {
  question: {
    choiceLabels: readonly string[] | null
    choiceCount: number
    lastIsNoneOfTheAbove: boolean
  }
  answers: readonly number[] | null
  setAnswer: (choices: number[]) => void
  showNextButton: boolean
  onNextButtonClicked: () => void
}

function CheckboxQuestion({
  answers,
  question: { choiceCount, choiceLabels, lastIsNoneOfTheAbove },
  setAnswer,
  showNextButton,
  onNextButtonClicked,
}: CheckboxQuestionProps) {
  const labels =
    choiceLabels ??
    [...Array(choiceCount)].map((_, n) =>
      (choiceCount == 11 ? n : n + 1).toString()
    )

  // Check if any label is longer than 3 characters
  const hasLongLabels = labels.some((label) => label.length > 3)

  // FIXME: don't let the user uncheck the last box
  const handleChange = (n: number) => {
    if (answers?.includes(n)) {
      setAnswer(answers.filter((a) => a !== n))
    } else {
      if (lastIsNoneOfTheAbove && n === choiceCount - 1) {
        // If selecting "None of the Above", deselect all others
        setAnswer([n])
      } else {
        // If selecting any other option, deselect "None of the Above"
        setAnswer(
          [...(answers || []), n].filter(
            (a) => a !== choiceCount - 1 || !lastIsNoneOfTheAbove
          )
        )
      }
    }
  }

  return (
    <div className="space-y-4">
      <div
        className={
          'group mx-auto ' +
          (hasLongLabels
            ? 'flex flex-col space-y-2'
            : 'flex w-full items-center justify-between sm:justify-center sm:gap-6')
        }
      >
        {[...Array(choiceCount)].map((_, n) => (
          <label
            key={n}
            className={
              'flex cursor-pointer items-center ' +
              (hasLongLabels
                ? 'w-full rounded-md p-2 text-sm font-semibold '
                : 'h-11 w-11 justify-center rounded-md text-sm font-semibold ') +
              (answers?.includes(n)
                ? 'bg-flintBlue text-white'
                : 'bg-slate-200 opacity-50 hover:opacity-100')
            }
          >
            <input
              type="checkbox"
              value={n.toString()}
              checked={answers?.includes(n)}
              onChange={() => handleChange(n)}
              className="sr-only"
            />
            <span className={hasLongLabels ? 'ml-3' : ''}>{labels[n]}</span>
          </label>
        ))}
      </div>
      {showNextButton ? (
        <div className="flex justify-end">
          <Button
            onClick={() => {
              onNextButtonClicked()
            }}
          >
            Next Question
            <ArrowRight size={16} />
          </Button>
        </div>
      ) : null}
    </div>
  )
}

type QuestionCardProps = {
  answerRef: QuestionCardFragment$key
  learnerRef: QuestionCardLearnerFragment$key | null
  template?: (str: string) => string
  showNextButton?: boolean
  onNextButtonClicked?: () => void
  showPreviousAnswer: boolean
  expectationsAsHover: boolean
  randomize?: boolean
}
export function QuestionCard({
  answerRef,
  learnerRef,
  template = (str) => str,
  showNextButton = false,
  onNextButtonClicked,
  showPreviousAnswer,
  randomize = false,
  expectationsAsHover,
}: QuestionCardProps) {
  const answer = useFragment(AnswerFragment, answerRef)
  const learner = useFragment(LearnerFragment, learnerRef) || null
  const [mutate, isMutating] =
    useMutation<QuestionCardAnswerMutation>(AnswerMutation)

  const setAnswer = useCallback(
    (variables: QuestionCardAnswerMutation$variables) => {
      return new Promise((resolve, reject) => {
        mutate({
          variables,
          onCompleted: resolve,
          onError(e) {
            toast.error('Unable to answer', {
              description: 'Please wait a moment and try again.',
              duration: 5000,
            })
            reject(e)
          },
        })
      })
    },
    [mutate]
  )

  useEffect(() => {
    if (randomize && !isMutating) {
      const choice = Math.floor(Math.random() * answer.question.choiceCount)
      setAnswer({
        answer: answer.id,
        ...(answer.question.choiceCount === 0
          ? { text: '(randomized)', choice: 0 }
          : answer.question.multipleResponses
            ? { choices: [choice] }
            : { choice }),
      })
    }
  }, [setAnswer, randomize, answer, isMutating])

  const choiceCount = answer.question.choiceCount
  const hackedNoExpectations = [
    '6b902163-398e-4f35-a328-3db5721c3966',
    '311df3a0-1971-4d70-924c-41b2b57ea462',
    '945e6986-b890-4c5b-af5d-58d5a62a9d88',
  ]

  return (
    <motion.div
      className="group"
      initial={
        answer.ordinal !== 0
          ? {
              height: 0,
              opacity: 0,
            }
          : {}
      }
      animate={
        answer.ordinal !== 0
          ? {
              height: 'auto',
              opacity: 1,
              transition: {
                height: {
                  duration: 0.75,
                  ease: [0.215, 0.61, 0.355, 1.0],
                },
                opacity: {
                  duration: 0.6,
                  delay: 0.2,
                },
              },
            }
          : {}
      }
    >
      <div className="space-y-4 py-4">
        <h3 className="font-semibold">
          <Prompt answerRef={answer} learnerRef={learner} template={template} />
        </h3>
        {choiceCount === 0 ? (
          <TextQuestion
            answer={answer.text}
            randomize={!isMutating && randomize}
            setAnswer={(text) =>
              // FIXME: It would seem more principled to store only the `text`
              // and not an incorrect `choice` value, especially since we now
              // have `multipleResponses` questions that don't store a single
              // `choice`. But it seems we've always stored a zero value for
              // the `choice` in the past, so I'm preserving that behavior
              // until it's proven safe to remove.
              setAnswer({ answer: answer.id, choice: 0, text })
            }
            showNextButton={showNextButton}
            onNextButtonClicked={() =>
              onNextButtonClicked ? onNextButtonClicked() : null
            }
          />
        ) : answer.question.multipleResponses ? (
          <CheckboxQuestion
            answers={answer.choices}
            question={answer.question}
            showNextButton={showNextButton}
            onNextButtonClicked={() =>
              onNextButtonClicked ? onNextButtonClicked() : null
            }
            setAnswer={(choices) => setAnswer({ answer: answer.id, choices })}
          />
        ) : (
          <RadioQuestion
            answer={answer.choice}
            question={answer.question}
            previousAnswer={showPreviousAnswer ? answer.previousChoice : null}
            setAnswer={(choice) => {
              setAnswer({
                answer: answer.id,
                choice,
              })
              if (onNextButtonClicked) onNextButtonClicked()
            }}
          />
        )}
      </div>
      {choiceCount != 11 ||
      hackedNoExpectations.includes(answer.question.id) ? null : (
        <div
          className={
            'flex text-center text-sm font-semibold ' +
            (expectationsAsHover
              ? 'opacity-0 group-hover:opacity-70'
              : 'opacity-70')
          }
        >
          {learner ? (
            <>
              <span className="w-1/3">Below expectations</span>
              <span className="w-1/3">Meets expectations</span>
              <span className="w-1/3">Exceeds expectations</span>
            </>
          ) : (
            <>
              <span className="w-1/3">I see room for growth</span>
              <span className="w-1/3">I am as good as I need to be</span>
              <span className="w-1/3">I am very good at this</span>
            </>
          )}
        </div>
      )}
    </motion.div>
  )
}
