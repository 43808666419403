import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'

import { Link, useParams } from '@tanstack/react-router'

import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { type PastActivityQuery } from './__generated__/PastActivityQuery.graphql'
import { Hero } from '@/components/lms/Hero'
import { NotFoundComponent } from '@/routes/lms/NotFound'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import Activity from '@/components/lms/Activity'
import { Card, CardContent } from '@/components/Card'
import { PastActivity_assignment$key } from './__generated__/PastActivity_assignment.graphql'

const Query = graphql`
  query PastActivityQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Assignment {
        ...PastActivity_assignment
        learner {
          id
        }
        initiative {
          id
        }
        activity {
          eligibleForAssignment
        }
      }
    }
    learner {
      ...AppContainer_learner
      id
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`

const Fragment = graphql`
  fragment PastActivity_assignment on Assignment {
    id
    learner {
      id
      fullName
    }
    ...ActivityFragment
  }
`

type PastActivityComponentProps = {
  assignment?: PastActivity_assignment$key | null
  showBreadcrumbs: boolean
  allowAssignment: boolean
}

function PastActivityComponent({
  assignment: key,
  showBreadcrumbs,
  allowAssignment,
}: PastActivityComponentProps) {
  const assignment = useFragment(Fragment, key)

  if (!assignment) {
    return <NotFoundComponent />
  }

  return (
    <>
      <Hero>
        {showBreadcrumbs ? (
          <Breadcrumbs>
            <Link
              to="/lms/my-learners"
              className="duration-150 hover:text-flintOrange"
            >
              My Learners
            </Link>
            <Link
              to="/lms/my-learners/$myLearnerID"
              params={{ myLearnerID: assignment.learner.id }}
              className="duration-150 hover:text-flintOrange"
            >
              {assignment.learner?.fullName}
            </Link>
            <span>(Past Activity)</span>
          </Breadcrumbs>
        ) : null}
      </Hero>
      <Card>
        <CardContent>
          <Activity
            assignment={assignment}
            variant={allowAssignment ? 'redoable' : 'completed'}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default function PastActivity() {
  const { assignmentID } = useParams({
    from: '/lms/past-activity/$assignmentID',
  })
  const data = useLazyLoadQuery<PastActivityQuery>(Query, {
    id: assignmentID || '',
  })
  const assignment = data.node?.__typename === 'Assignment' ? data.node : null

  const isMe = data.learner?.id === assignment?.learner.id
  const isCurrentInitiative =
    data.learner?.currentInitiative.edges.some(
      (edge) => edge.node.id === assignment?.initiative?.id
    ) || false

  return (
    <AppContainer
      page="past-activity"
      learner={data.learner}
      highlight={
        data.learner?.id !== assignment?.learner.id ? 'My Learners' : undefined
      }
    >
      <PastActivityComponent
        assignment={assignment}
        showBreadcrumbs={!isMe}
        allowAssignment={
          // eslint-disable-next-line react/jsx-no-leaked-render
          isMe &&
          isCurrentInitiative &&
          (assignment?.activity.eligibleForAssignment || false)
        }
      />
    </AppContainer>
  )
}
