/**
 * @generated SignedSource<<4c001c9eebd5b456c0c5d59f62e98375>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LearningProfile_RevokeMutation$variables = Record<PropertyKey, never>;
export type LearningProfile_RevokeMutation$data = {
  readonly revokeProfileResponses: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly choice: number | null;
      readonly id: string;
      readonly text: string | null;
    }>;
    readonly id: string;
  }>;
};
export type LearningProfile_RevokeMutation = {
  response: LearningProfile_RevokeMutation$data;
  variables: LearningProfile_RevokeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SurveyResponse",
    "kind": "LinkedField",
    "name": "revokeProfileResponses",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "SurveyAnswer",
        "kind": "LinkedField",
        "name": "answers",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "choice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LearningProfile_RevokeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LearningProfile_RevokeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72f28d31660dadeddf5096cf4701406c",
    "id": null,
    "metadata": {},
    "name": "LearningProfile_RevokeMutation",
    "operationKind": "mutation",
    "text": "mutation LearningProfile_RevokeMutation {\n  revokeProfileResponses {\n    id\n    answers {\n      id\n      choice\n      text\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d905537f8ba54c206c9ea9ee3a27c640";

export default node;
