/**
 * @generated SignedSource<<b2d88434f155328392718bfae5d37164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FirstTimeDialogMutation$variables = {
  initiative: string;
};
export type FirstTimeDialogMutation$data = {
  readonly seenInitiativePopup: {
    readonly currentInitiative: {
      readonly edges: ReadonlyArray<{
        readonly initiative: {
          readonly id: string;
        };
        readonly seenPopup: boolean;
      }>;
    };
  };
};
export type FirstTimeDialogMutation = {
  response: FirstTimeDialogMutation$data;
  variables: FirstTimeDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiative"
  }
],
v1 = {
  "kind": "Literal",
  "name": "popupType",
  "value": "InitiativePopupIntro"
},
v2 = [
  {
    "kind": "Variable",
    "name": "initiative",
    "variableName": "initiative"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "currentInitiative",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "atDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": "initiative",
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "seenPopup",
          "storageKey": "seenPopup(popupType:\"InitiativePopupIntro\")"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FirstTimeDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativePopup",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FirstTimeDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativePopup",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5febeb6602c86f1cf54f5649a300f188",
    "id": null,
    "metadata": {},
    "name": "FirstTimeDialogMutation",
    "operationKind": "mutation",
    "text": "mutation FirstTimeDialogMutation(\n  $initiative: ID!\n) {\n  seenInitiativePopup(initiative: $initiative, popupType: InitiativePopupIntro) {\n    currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n      edges {\n        initiative: node {\n          id\n        }\n        seenPopup(popupType: InitiativePopupIntro)\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "94c228ad159e19e5e2556fd9d22283b2";

export default node;
