import {
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogClose,
  DialogTitle,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { Button } from '@/components/Button'
import { graphql, useFragment } from 'react-relay'
import { FirstTimeDialogEdgeFragment$key } from './__generated__/FirstTimeDialogEdgeFragment.graphql'
import { CalendarCheck, CalendarX } from 'lucide-react'
import { AcknowledgedDialog } from '@/components/AcknowledgedDialog'
import { FirstTimeDialogLearnerFragment$key } from './__generated__/FirstTimeDialogLearnerFragment.graphql'

const LearnerFragment = graphql`
  fragment FirstTimeDialogLearnerFragment on Learner {
    achievementCategories {
      achievements {
        acknowledged
        completionDate
      }
    }
  }
`

const EdgeFragment = graphql`
  fragment FirstTimeDialogEdgeFragment on LearnerInitiativeEdge {
    seenIntro: seenPopup(popupType: InitiativePopupIntro)
    initiative: node {
      id
      name
      introContent
    }
    startDate
    endDate
  }
`

const Mutation = graphql`
  mutation FirstTimeDialogMutation($initiative: ID!) {
    seenInitiativePopup(
      initiative: $initiative
      popupType: InitiativePopupIntro
    ) {
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          initiative: node {
            id
          }
          seenPopup(popupType: InitiativePopupIntro)
        }
      }
    }
  }
`

type FirstTimeDialogProps = {
  learnerRef: FirstTimeDialogLearnerFragment$key
  edgeRef: FirstTimeDialogEdgeFragment$key
}

export function FirstTimeDialog({ learnerRef, edgeRef }: FirstTimeDialogProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const startDate = new Date(data.startDate)
  const endDate = data.endDate ? new Date(data.endDate) : null

  const hasUnacknowledgedAchievement = !!learner.achievementCategories
    .flatMap((x) => x.achievements)
    .find((x) => !x.acknowledged && x.completionDate)

  if (hasUnacknowledgedAchievement) return null

  return (
    <AcknowledgedDialog
      Mutation={Mutation}
      variables={{ initiative: data.initiative.id }}
      beenSeen={data.seenIntro}
    >
      <DialogContent size="md">
        <DialogHeader>
          <DialogTitle>Initiative Started: {data.initiative.name}</DialogTitle>
          <div className="flex gap-6 text-sm">
            <div className="flex items-center gap-1.5">
              <CalendarCheck size={16} className="text-flintBlue" />
              <p>
                <span className="font-medium">Start: </span>
                <span>{startDate.toLocaleDateString()}</span>
              </p>
            </div>
            <div className="flex items-center gap-1.5">
              <CalendarX size={16} className="text-flintBlue" />
              <p>
                <span className="font-medium">End: </span>
                <span>{endDate ? endDate.toLocaleDateString() : ''}</span>
              </p>
            </div>
          </div>
        </DialogHeader>
        <div>
          <Markdown>{data.initiative.introContent}</Markdown>
        </div>

        <DialogFooter className="flex justify-end">
          <DialogClose asChild>
            <Button>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </AcknowledgedDialog>
  )
}
