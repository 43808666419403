import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/Select'
import { graphql } from 'relay-runtime'
import { useMutation } from 'react-relay'

const Mutation = graphql`
  mutation LocaleSelectMutation($input: LearnerUpdate!) {
    updateLearner(input: $input) {
      id
      locale
    }
  }
`

function LocaleSelect({ locale }: { locale: string }) {
  const [setLocale] = useMutation(Mutation)

  const availableLocales = [
    { value: 'en-US', label: 'English' },
    { value: 'fr-CA', label: 'Français' },
  ]

  const handleLocaleChange = (locale: string) => {
    setLocale({
      variables: {
        input: { locale },
      },
      onCompleted: () => {
        window.location.reload()
      },
    })
  }

  return (
    <Select onValueChange={handleLocaleChange}>
      <SelectTrigger className="w-[180px]">
        <SelectValue
          placeholder={availableLocales.find((l) => l.value === locale)?.label}
        />
      </SelectTrigger>
      <SelectContent>
        {availableLocales.map((locale) => (
          <SelectItem key={locale.value} value={locale.value} translate="no">
            {locale.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default LocaleSelect
