/**
 * @generated SignedSource<<18ceb27d73e9fd5bc0dba6ed2c047637>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CompletedDialogMutation$variables = {
  initiative: string;
};
export type CompletedDialogMutation$data = {
  readonly seenInitiativePopup: {
    readonly currentInitiative: {
      readonly edges: ReadonlyArray<{
        readonly initiative: {
          readonly id: string;
        };
        readonly seenCompleted: boolean;
      }>;
    };
  };
};
export type CompletedDialogMutation = {
  response: CompletedDialogMutation$data;
  variables: CompletedDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiative"
  }
],
v1 = {
  "kind": "Literal",
  "name": "popupType",
  "value": "InitiativePopupCompleted"
},
v2 = [
  {
    "kind": "Variable",
    "name": "initiative",
    "variableName": "initiative"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "currentInitiative",
  "args": [
    {
      "kind": "Literal",
      "name": "membershipFilter",
      "value": {
        "atDate": 0
      }
    }
  ],
  "concreteType": "LearnerInitiativeConnection",
  "kind": "LinkedField",
  "name": "initiatives",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerInitiativeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": "seenCompleted",
          "args": [
            (v1/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "seenPopup",
          "storageKey": "seenPopup(popupType:\"InitiativePopupCompleted\")"
        },
        {
          "alias": "initiative",
          "args": null,
          "concreteType": "Initiative",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompletedDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativePopup",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompletedDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "seenInitiativePopup",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "376f612b4f733b0b9a7dc450b6c3172d",
    "id": null,
    "metadata": {},
    "name": "CompletedDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CompletedDialogMutation(\n  $initiative: ID!\n) {\n  seenInitiativePopup(initiative: $initiative, popupType: InitiativePopupCompleted) {\n    currentInitiative: initiatives(membershipFilter: {atDate: 0}) {\n      edges {\n        seenCompleted: seenPopup(popupType: InitiativePopupCompleted)\n        initiative: node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "86464cd2742ec3265945cd64d92a30b9";

export default node;
