/**
 * @generated SignedSource<<c2e7fd15e2bd293b0b494b0858b155e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompletedActivitiesCard_assignments$data = ReadonlyArray<{
  readonly activity: {
    readonly name: string;
  };
  readonly completionDate: number | null;
  readonly id: string;
  readonly " $fragmentType": "CompletedActivitiesCard_assignments";
}>;
export type CompletedActivitiesCard_assignments$key = ReadonlyArray<{
  readonly " $data"?: CompletedActivitiesCard_assignments$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedActivitiesCard_assignments">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompletedActivitiesCard_assignments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Activity",
      "kind": "LinkedField",
      "name": "activity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completionDate",
      "storageKey": null
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};

(node as any).hash = "6ce20e51fc155ae4ce65f58cfafc525a";

export default node;
