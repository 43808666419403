/**
 * @generated SignedSource<<6c18a1d6b8804ab7842e00878873049c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PastActivity_assignment$data = {
  readonly id: string;
  readonly learner: {
    readonly fullName: string | null;
    readonly id: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActivityFragment">;
  readonly " $fragmentType": "PastActivity_assignment";
};
export type PastActivity_assignment$key = {
  readonly " $data"?: PastActivity_assignment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PastActivity_assignment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PastActivity_assignment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "learner",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityFragment"
    }
  ],
  "type": "Assignment",
  "abstractKey": null
};
})();

(node as any).hash = "6efca31e16f8f12965cf865043e0e4ce";

export default node;
