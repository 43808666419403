/**
 * @generated SignedSource<<503c0c642ab89f9dd2b273904afa9c55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewAchievementDialogMutation$variables = {
  achievement: string;
};
export type NewAchievementDialogMutation$data = {
  readonly acknowledgeAchievement: {
    readonly " $fragmentSpreads": FragmentRefs<"NewAchievementDialogFragment">;
  } | null;
};
export type NewAchievementDialogMutation = {
  response: NewAchievementDialogMutation$data;
  variables: NewAchievementDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "achievement"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "achievement",
    "variableName": "achievement"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAchievementDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "acknowledgeAchievement",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewAchievementDialogFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewAchievementDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Learner",
        "kind": "LinkedField",
        "name": "acknowledgeAchievement",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearnerAchievementCategory",
            "kind": "LinkedField",
            "name": "achievementCategories",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnerAchievement",
                "kind": "LinkedField",
                "name": "achievements",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "acknowledged",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completionDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Achievement",
                    "kind": "LinkedField",
                    "name": "achievement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completionDescription",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showCompletion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2acfcd36b293a20e98da7c2144e3812c",
    "id": null,
    "metadata": {},
    "name": "NewAchievementDialogMutation",
    "operationKind": "mutation",
    "text": "mutation NewAchievementDialogMutation(\n  $achievement: ID!\n) {\n  acknowledgeAchievement(achievement: $achievement) {\n    ...NewAchievementDialogFragment\n    id\n  }\n}\n\nfragment NewAchievementDialogFragment on Learner {\n  achievementCategories {\n    achievements {\n      acknowledged\n      completionDate\n      achievement {\n        id\n        name\n        completionDescription\n        showCompletion\n        imageUrl\n        startDate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "65604391ecba360ad28e58ccb07ac564";

export default node;
