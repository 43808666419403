const BOX_COUNT = 10

function BoxGraph({
  value,
  label,
  color,
}: {
  value: number
  label?: string
  color: string
}) {
  return (
    <div className="space-y-2">
      {label ? <h3 className="text-slate-600">{label}</h3> : null}
      <div className="flex gap-1">
        {[...Array(BOX_COUNT)].map((_, i) => (
          <div
            key={i}
            className={`h-4 w-4 rounded ${
              i < value * BOX_COUNT ? color : 'bg-slate-200'
            }`}
          />
        ))}
      </div>
    </div>
  )
}

function PracticeRating({
  self,
  manager,
  showLabel = true,
}: {
  self?: number | null
  manager?: number | null
  showLabel?: boolean
}) {
  return (
    <div className="block space-y-3">
      {self ? (
        <BoxGraph
          value={self}
          label={showLabel ? 'Self Rating' : undefined}
          color="bg-flintBlue"
        />
      ) : null}
      {manager ? (
        <BoxGraph
          value={manager}
          label={showLabel ? 'Manager Rating' : undefined}
          color="bg-flintOrange"
        />
      ) : null}
    </div>
  )
}

export default PracticeRating
