import { RootRoute, createRoute, redirect } from '@tanstack/react-router'
import LMSRedirect from '@/routes/lms/LMSRedirect'
import LMSLogout from '@/routes/lms/LMSLogout'
import Login from '@/routes/lms/Login'
import GeneralSurvey from '@/routes/lms/GeneralSurvey'
import Waiting from '@/routes/lms/Activity/Waiting'
import Activity from '@/routes/lms/Activity/Activity'
import PastActivity from '@/routes/lms/PastActivity'
import Pathway from '@/routes/lms/Pathway'
import Contest from '@/routes/lms/Contest'
import ProvideEmail from '@/routes/lms/ProvideEmail'
import VerifyEmail from '@/routes/lms/VerifyEmail'
import NotFound from '@/routes/lms/NotFound'
import MyLearners from '@/routes/lms/MyLearners/MyLearners'
import Learner from '@/routes/lms/MyLearners/MyLearner'
import LearningProfile from '@/routes/lms/LearningProfile'

import Cookies from 'js-cookie'
import { z } from 'zod'
import { getIdToken } from '@/common/auth'
import About from './About'
import Orientation from './Orientation'
import ActivityRedirect from './ActivityRedirect'
import ChooseInitiative from './Initiatives/ChooseInitiative'
import Achievements from './Achievements'
import SelectInitiative from './Initiatives/SelectInitiative'
import PrintActivity from './PrintActivity'
import { CurrentInitiative } from './Initiatives/CurrentInitiative'
import { PastInitiatives } from './Initiatives/PastInitiatives'

async function verifyToken(maybeToken: string | undefined) {
  if (maybeToken) {
    Cookies.set('flintls-access-token', maybeToken)
    localStorage.setItem('flintls-access-token', maybeToken)
  }

  const maybeResolvedToken = await getIdToken()
  if (!maybeResolvedToken) {
    throw redirect({ to: '/lms/login' })
  }
}

const getLMSRoutes = (rootRouteGetter: () => RootRoute) => {
  const redirectRoute = createRoute({
    getParentRoute: rootRouteGetter,
    path: '/lms',
    validateSearch: z.object({
      access_token: z.string().optional(),
      from: z.string().optional(),
      complete: z.boolean().optional(),
    }),
    beforeLoad: async (opts) => {
      await verifyToken(opts.search.access_token)
    },
    component: LMSRedirect,
  })

  const loginRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Login,
    path: '/lms/login',
  })

  const printActivityRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: PrintActivity,
    path: '/lms/print-activity/$assignmentID',
  })

  const logoutRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: LMSLogout,
    path: '/lms/logout',
  })

  const generalSurveyRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: GeneralSurvey,
    path: '/lms/general-survey',
  })

  const waitingRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Waiting,
    path: '/lms/waiting',
  })

  const specificActivityRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: ActivityRedirect,
    path: '/lms/activity/$assignmentID',
    validateSearch: z.object({
      access_token: z.string().optional(),
    }),
    beforeLoad: async (opts) => {
      await verifyToken(opts.search.access_token)
    },
  })

  const activityRoute = createRoute({
    getParentRoute: rootRouteGetter,
    validateSearch: z.object({
      complete: z.boolean().optional(),
    }),
    component: Activity,
    path: '/lms/activity',
  })

  const pastActivityRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: PastActivity,
    path: '/lms/past-activity/$assignmentID',
  })

  const pathwayRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Pathway,
    path: '/lms/pathway',
  })

  const contestRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Contest,
    path: '/lms/contests/$contestID',
  })

  const provideEmailRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: ProvideEmail,
    path: '/lms/provide-email',
  })

  const verifyEmailRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: VerifyEmail,
    path: '/lms/verify-email',
    validateSearch: z.object({
      verification: z.string().optional(),
      access_token: z.string().optional(),
    }),
    beforeLoad: (opts) => {
      verifyToken(opts.search.access_token)
    },
  })

  const notFoundRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: NotFound,
    path: '/lms/*',
  })

  const myLearnersRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: MyLearners,
    path: '/lms/my-learners',
  })

  const learnerRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Learner,
    path: '/lms/my-learners/$myLearnerID',
  })

  const learningProfileRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: LearningProfile,
    path: '/lms/learning-profile',
  })

  const aboutRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: About,
    path: '/lms/about',
  })

  const orientationRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Orientation,
    path: '/lms/orientation',
  })

  const chooseInitiativeRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: ChooseInitiative,
    path: '/lms/choose-initiative/$initiative',
  })

  const achievementsRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: Achievements,
    path: '/lms/achievements',
  })

  const selectInitiativeRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: SelectInitiative,
    path: '/lms/initiatives/select',
  })

  const currentInitiativeRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: CurrentInitiative,
    path: '/lms/initiatives/current',
  })

  const pastInitiativesRoute = createRoute({
    getParentRoute: rootRouteGetter,
    component: PastInitiatives,
    path: '/lms/initiatives/past',
  })

  return [
    redirectRoute,
    loginRoute,
    logoutRoute,
    generalSurveyRoute,
    waitingRoute,
    specificActivityRoute,
    printActivityRoute,
    activityRoute,
    pastActivityRoute,
    pathwayRoute,
    contestRoute,
    provideEmailRoute,
    verifyEmailRoute,
    notFoundRoute,
    myLearnersRoute,
    learnerRoute,
    learningProfileRoute,
    aboutRoute,
    orientationRoute,
    chooseInitiativeRoute,
    achievementsRoute,
    selectInitiativeRoute,
    currentInitiativeRoute,
    pastInitiativesRoute,
  ]
}

export default getLMSRoutes
