import { useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { InitiativeSummaryCard } from './InitiativeSummaryCard'
import { BehaviorsCard } from './BehaviorsCard'
import { CompletedActivitiesCard } from './CompletedActivitiesCard'
import { ProgressCard } from './ProgressCard'
import { InitiativeEdgeFragment$key } from './__generated__/InitiativeEdgeFragment.graphql'
import { InitiativeLearnerFragment$key } from './__generated__/InitiativeLearnerFragment.graphql'
import { PracticesCard } from './PracticesCard'

const EdgeFragment = graphql`
  fragment InitiativeEdgeFragment on LearnerInitiativeEdge {
    assignments(filter: { chosen: true, feedbackCompleted: true }) {
      nodes {
        ...CompletedActivitiesCard_assignments
      }
    }
    ...InitiativeSummaryCardFragment
    ...BehaviorsCardEdgeFragment
    ...PracticesCardEdgeFragment
    ...goalProgressHookEdgeFragment
  }
`

const LearnerFragment = graphql`
  fragment InitiativeLearnerFragment on Learner {
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      edges {
        ...InitiativeEdgeFragment
      }
    }
    ...goalProgressHookLearnerFragment
    ...BehaviorsCardLearnerFragment
    ...PracticesCardLearnerFragment
    ...NextActionButtonLearnerFragment
  }
`

type InitiativeProps = {
  edgeRef: InitiativeEdgeFragment$key
  learnerRef: InitiativeLearnerFragment$key
  learnerIsCurrent: boolean
  showInteractivity?: boolean
  activityCompleted?: boolean
  activityIncompleted?: boolean
}

export default function Initiative({
  edgeRef,
  learnerRef,
  showInteractivity = false,
  learnerIsCurrent,
}: InitiativeProps) {
  const edge = useFragment(EdgeFragment, edgeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const [focusedBox, setFocusedBox] = useState<
    | 'none'
    | 'initiative'
    | 'completed-activities'
    | 'pathway'
    | 'behaviors'
    | 'practices'
  >('none')

  return (
    <div className="flex grid-cols-3 flex-col gap-6 lg:grid">
      <InitiativeSummaryCard
        edgeRef={edge}
        learnerRef={learner}
        showInteractivity={showInteractivity}
        hidden={focusedBox !== 'none' && focusedBox !== 'initiative'}
      />
      <CompletedActivitiesCard
        assignments={edge.assignments.nodes}
        learnerIsCurrent={learnerIsCurrent}
        focused={focusedBox === 'completed-activities'}
        onFocus={() => setFocusedBox('completed-activities')}
        onBlur={() => setFocusedBox('none')}
      />
      <ProgressCard
        learnerRef={learner}
        edgeRef={edge}
        animated={false}
        hidden={focusedBox !== 'none' && focusedBox !== 'pathway'}
        showMore={learnerIsCurrent}
      />
      <PracticesCard
        edgeRef={edge}
        learner={learner}
        hidden={focusedBox !== 'none' && focusedBox !== 'practices'}
      />
      <BehaviorsCard
        edgeRef={edge}
        learner={learner}
        hidden={focusedBox !== 'none' && focusedBox !== 'behaviors'}
      />
    </div>
  )
}
