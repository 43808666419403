import {
  Environment,
  Network,
  RecordSource,
  Store,
  FetchFunction,
} from 'relay-runtime'

import { getIdToken, getRole } from '@/common/auth'

import * as Sentry from '@sentry/react'
import {
  RelayEnvironmentProvider as RelayEnvironmentProviderBroken,
  Environment as EnvironmentBroken,
} from 'react-relay'

const fetchFn: FetchFunction = async (request, variables) => {
  const idToken = await getIdToken()

  const body = {
    query: request.text, // <-- The GraphQL document composed by Relay
    variables,
  }

  const resp = await fetch(import.meta.env.VITE_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept:
        'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + idToken,
      'X-Flint-Role': getRole(),
    },
    body: JSON.stringify(body),
  })

  const json = await resp.json()

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.log(body, json)
  }

  if (json.errors) {
    Sentry.captureException(new Error('error from graphql'), {
      extra: {
        graphql_errors: JSON.stringify(json.errors),
        graphql_response: json,
        graphql_request: body,
      },
    })
    if (
      !window.location.href.includes('/sso/') &&
      !window.location.href.includes('/admin/') &&
      import.meta.env.PROD
    ) {
      window.location.href = '/lms/logout'
    }
  }

  return json
}

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchFn),
    store: new Store(new RecordSource()),
  })
}

export const RelayEnvironment = createRelayEnvironment()

// BS to fix this issue: https://github.com/facebook/relay/issues/4598
export const RelayEnvironmentProvider =
  RelayEnvironmentProviderBroken as unknown as (props: {
    children: React.ReactNode
    environment: EnvironmentBroken
  }) => React.ReactElement
