import { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/Card'
import { Link } from '@tanstack/react-router'
import { Button } from '@/components/Button'
import { useGoalProgress } from './goal-progress-hook'
import { goalProgressHookLearnerFragment$key } from './__generated__/goalProgressHookLearnerFragment.graphql'
import { goalProgressHookEdgeFragment$key } from './__generated__/goalProgressHookEdgeFragment.graphql'
import { Star } from 'lucide-react'

interface ProgressCardProps {
  learnerRef: goalProgressHookLearnerFragment$key
  edgeRef: goalProgressHookEdgeFragment$key
  animated: boolean
  hidden: boolean
  showMore?: boolean
}

export function ProgressCard({
  learnerRef,
  edgeRef,
  animated,
  hidden,
  showMore = true,
}: ProgressCardProps) {
  const { totalSteps, stepsCompleted, hasPathway } = useGoalProgress(
    learnerRef,
    edgeRef
  )

  const calculateProgress = useCallback(
    (steps: number) => {
      return (steps / (totalSteps || 1)) * 100
    },
    [totalSteps]
  )

  const [barWidth, setBarWidth] = useState(
    calculateProgress(animated ? stepsCompleted - 1 : stepsCompleted)
  )

  useEffect(() => {
    if (animated) {
      setTimeout(() => {
        setBarWidth(calculateProgress(stepsCompleted))
      }, 200)
    }
  }, [animated, calculateProgress, stepsCompleted])

  if (!totalSteps) return null

  return (
    <Card
      className={`relative transition-opacity duration-500 ${hidden ? 'lg:opacity-0' : 'lg:opacity-100'}`}
      animated={animated}
    >
      <CardHeader>
        <CardTitle size="sm">Progress</CardTitle>
      </CardHeader>
      <CardContent className="text-sm">
        <div className="space-y-3 pb-20">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-end gap-2">
              <span className="font-medium">
                {stepsCompleted} / {totalSteps} activities completed
              </span>
              {stepsCompleted > totalSteps && (
                <Star className="h-5 w-5 text-flintOrange" />
              )}
            </div>
          </div>

          <div className="relative h-3 w-full overflow-hidden rounded-full bg-slate-200">
            <div
              className="absolute h-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
              style={{
                width: `${Math.min(barWidth, 100)}%`,
                transitionDuration: '1500ms',
                transitionDelay: '1s',
              }}
            />
            {stepsCompleted > totalSteps && (
              <div
                className="absolute h-full bg-flintOrange"
                style={{
                  width: `${Math.min(((stepsCompleted - totalSteps) / stepsCompleted) * 100, 100)}%`,
                  right: '0px',
                  transitionDuration: '1500ms',
                  transitionDelay: '1s',
                }}
              />
            )}
          </div>

          {stepsCompleted > totalSteps && (
            <div className="flex items-center gap-2">
              <Star className="h-4 w-4 shrink-0 text-flintOrange" />
              <p className="text-xs leading-4 text-slate-600">
                Amazing work! You&apos;ve completed{' '}
                {stepsCompleted - totalSteps} bonus activities.
              </p>
            </div>
          )}
        </div>
        {hasPathway && showMore ? (
          <CardFooter className="absolute bottom-0 left-0">
            <Button>
              <Link to="/lms/pathway">View More</Link>
            </Button>
          </CardFooter>
        ) : null}
      </CardContent>
    </Card>
  )
}
