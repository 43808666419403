import { graphql, useFragment } from 'react-relay'
import type { AppContainer_learner$key } from './__generated__/AppContainer_learner.graphql'
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from '@/components/Sidebar'
import AppSidebar, { AppSidebarProps } from './AppSidebar'
import { NewAchievementDialog } from '../NewAchievementDialog'
import InteractionProvider from '@/components/Interaction'

const Fragment = graphql`
  fragment AppContainer_learner on Learner {
    ...AppSidebar_learner
    ...NewAchievementDialogFragment
  }
`

type AppContainerProps = {
  learner: AppContainer_learner$key | null
  children: React.ReactNode
  page: string
  highlight?: AppSidebarProps['highlight']
}

function AppContainer({
  learner,
  children,
  page,
  highlight,
}: AppContainerProps) {
  const data = useFragment(Fragment, learner)
  if (!data) return <div className="contain h-screen space-y-6">{children}</div>

  return (
    <InteractionProvider page={page}>
      <SidebarProvider>
        <NewAchievementDialog edgeRef={data} />
        <AppSidebar learner={data} highlight={highlight} />
        <SidebarInset>
          <div className="flex h-16 items-center justify-between">
            <SidebarTrigger />
          </div>
          <div className="contain space-y-6">{children}</div>
        </SidebarInset>
      </SidebarProvider>
    </InteractionProvider>
  )
}

export default AppContainer
