import {
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from '@/components/Dialog'
import Markdown from '@/components/Markdown'
import { graphql, useFragment } from 'react-relay'
import { EndDialogEdgeFragment$key } from './__generated__/EndDialogEdgeFragment.graphql'
import { EndDialogLearnerFragment$key } from './__generated__/EndDialogLearnerFragment.graphql'
import { useGoalProgress } from './goal-progress-hook'
import { AcknowledgedDialog } from '@/components/AcknowledgedDialog'
import { Button } from '@/components/Button'

const LearnerFragment = graphql`
  fragment EndDialogLearnerFragment on Learner {
    ...goalProgressHookLearnerFragment
    ...NextActionButtonLearnerFragment
    achievementCategories {
      achievements {
        acknowledged
        completionDate
      }
    }
  }
`

const EdgeFragment = graphql`
  fragment EndDialogEdgeFragment on LearnerInitiativeEdge {
    ...goalProgressHookEdgeFragment
    seenEnd: seenPopup(popupType: InitiativePopupEnd)
    initiative: node {
      id
      name
      completionLt50Content
      completionGt50Content
      completionDoneContent
    }
  }
`

const Mutation = graphql`
  mutation EndDialogMutation($initiative: ID!) {
    seenInitiativePopup(
      initiative: $initiative
      popupType: InitiativePopupEnd
    ) {
      pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
        edges {
          seenEnd: seenPopup(popupType: InitiativePopupEnd)
          initiative: node {
            id
          }
        }
      }
    }
  }
`

type EndDialogProps = {
  edgeRef: EndDialogEdgeFragment$key
  learnerRef: EndDialogLearnerFragment$key
  onClose?: () => void
}

export function EndDialog({ edgeRef, learnerRef, onClose }: EndDialogProps) {
  const data = useFragment(EdgeFragment, edgeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const { totalSteps, stepsCompleted } = useGoalProgress(learner, data)

  const content =
    stepsCompleted >= totalSteps || stepsCompleted == 0
      ? data.initiative.completionDoneContent
      : stepsCompleted > 0.5 * totalSteps &&
          data.initiative.completionGt50Content !== ''
        ? data.initiative.completionGt50Content
        : stepsCompleted <= 0.5 * totalSteps &&
            data.initiative.completionLt50Content !== ''
          ? data.initiative.completionLt50Content
          : data.initiative.completionDoneContent

  const hasUnacknowledgedAchievement = !!learner.achievementCategories
    .flatMap((x) => x.achievements)
    .find((x) => !x.acknowledged && x.completionDate)

  // wait to show this dialog until the user has acknowledged all their achievements
  if (hasUnacknowledgedAchievement || content === '') return null

  return (
    <AcknowledgedDialog
      Mutation={Mutation}
      variables={{ initiative: data.initiative.id }}
      beenSeen={data.seenEnd}
      onClose={onClose}
    >
      <DialogContent
        size="md"
        closeBtn
        confetti={stepsCompleted >= 0.5 * totalSteps}
      >
        <DialogHeader>
          <DialogTitle>Initiative Ended: {data.initiative.name}</DialogTitle>
        </DialogHeader>
        <div>
          <Markdown>{content}</Markdown>
        </div>

        <DialogFooter className="flex justify-end">
          <DialogClose asChild>
            <Button>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </AcknowledgedDialog>
  )
}
