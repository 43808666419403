import { HelpCircle } from 'lucide-react'
import Markdown from '@/components/Markdown'
import { Button } from '@/components/Button'
import {
  Dialog,
  DialogTrigger,
  DialogTitle,
  DialogHeader,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
} from '@/components/Dialog'
import { graphql, useFragment } from 'react-relay'
import { Template } from './Template'
import { PromptLearnerFragment$key } from './__generated__/PromptLearnerFragment.graphql'
import {
  PromptAnswerFragment$data,
  PromptAnswerFragment$key,
} from './__generated__/PromptAnswerFragment.graphql'
import {
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
  CardTabs,
} from '@/components/CardTabs'
import { Card, CardContent } from '@/components/Card'

const AnswerFragment = graphql`
  fragment PromptAnswerFragment on SurveyAnswer {
    question {
      prompt
      behavior {
        id
        name
        longDescription
      }
      practices {
        id
        name
        longDescription
      }
    }
  }
`

const LearnerFragment = graphql`
  fragment PromptLearnerFragment on Learner {
    ...TemplateLearnerFragment
  }
`

type AdditionalInfoDialogProps = {
  answer: PromptAnswerFragment$data
}

function AdditionalInfoDialog({
  answer: {
    question: { behavior, practices },
  },
}: AdditionalInfoDialogProps) {
  const objects = [
    behavior?.longDescription ? behavior : null,
    ...practices.filter((p) => p.longDescription),
  ].filter((x) => x !== null)

  if (objects.length === 0) {
    return null
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button>
          <HelpCircle
            className="relative -top-0.5 ml-1 inline cursor-pointer stroke-flintBlue opacity-40 duration-200 hover:opacity-100"
            size={18}
            strokeWidth={2}
          />
        </button>
      </DialogTrigger>
      <DialogContent size="lg">
        <DialogHeader>
          <DialogTitle>
            {objects.length === 1 ? objects[0]?.name : 'Additional Information'}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {objects.length === 1 ? (
            <Markdown>{objects[0].longDescription}</Markdown>
          ) : (
            <CardTabs defaultValue={objects[0].id}>
              <CardTabsList>
                {objects.map((obj) => (
                  <CardTabsTrigger key={obj.id} value={obj.id}>
                    {obj.name}
                  </CardTabsTrigger>
                ))}
              </CardTabsList>
              {objects.map((obj) => (
                <CardTabsContent key={obj.id} value={obj.id}>
                  <Card tabs>
                    <CardContent>
                      <Markdown>{obj.longDescription}</Markdown>
                    </CardContent>
                  </Card>
                </CardTabsContent>
              ))}
            </CardTabs>
          )}
          <DialogFooter>
            <DialogClose asChild>
              <Button size="sm">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

type PromptProps = {
  answerRef: PromptAnswerFragment$key
  learnerRef: PromptLearnerFragment$key | null
  template: (str: string) => string
}

export function Prompt({ answerRef, learnerRef, template }: PromptProps) {
  const answer = useFragment(AnswerFragment, answerRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const q = answer.question

  return (
    <>
      <Template
        text={template(q.prompt)}
        learnerRef={learner}
        underline={true}
      />
      <AdditionalInfoDialog answer={answer} />
    </>
  )
}
