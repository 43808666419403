/**
 * @generated SignedSource<<4189c3f2a5225c855088d7ef368ff4b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewAchievementDialogFragment$data = {
  readonly achievementCategories: ReadonlyArray<{
    readonly achievements: ReadonlyArray<{
      readonly achievement: {
        readonly completionDescription: string;
        readonly id: string;
        readonly imageUrl: string;
        readonly name: string;
        readonly showCompletion: boolean;
        readonly startDate: number | null;
      };
      readonly acknowledged: boolean;
      readonly completionDate: number | null;
    }>;
  }>;
  readonly " $fragmentType": "NewAchievementDialogFragment";
};
export type NewAchievementDialogFragment$key = {
  readonly " $data"?: NewAchievementDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewAchievementDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewAchievementDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnerAchievementCategory",
      "kind": "LinkedField",
      "name": "achievementCategories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerAchievement",
          "kind": "LinkedField",
          "name": "achievements",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completionDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Achievement",
              "kind": "LinkedField",
              "name": "achievement",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completionDescription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showCompletion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};

(node as any).hash = "1949bfe5e39efdd1abd3130566a46ab1";

export default node;
