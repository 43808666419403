import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'
import { Link, useParams } from '@tanstack/react-router'

import { Hero, HeroTitle } from '@/components/lms/Hero'
import { NotFoundComponent } from '@/routes/lms/NotFound'

import type { MyLearnerQuery } from './__generated__/MyLearnerQuery.graphql'
import { Breadcrumbs } from '@/components/Breadcrumbs'
import { LearningProfile } from '@/components/LearningProfile/LearningProfile'

import MyLearnerSurvey from './MyLearnerSurvey'
import InitiativeSelector from '@/routes/lms/MyLearners/InitiativeSelector'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { MyLearner_learner$key } from './__generated__/MyLearner_learner.graphql'

const Query = graphql`
  query MyLearnerQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Learner {
        ...MyLearner_learner
      }
    }
    learner {
      ...AppContainer_learner
    }
  }
`
const Fragment = graphql`
  fragment MyLearner_learner on Learner {
    ...InitiativeSelectorLearnerFragment
    id
    email
    fullName
    lmsUserID
    initiatives {
      nodes {
        id
        showChoices
      }
    }
    personalityTest {
      ...PersonalityTestResult_result
    }

    customer {
      name
    }
    surveyAwaitingResponseFromManager {
      id
    }
    treatments {
      name
      experiment {
        name
      }
    }
    ...LearningProfile_learner
    ...MyLearnerSurveyFragment
  }
`

type MyLearnerComponentProps = {
  learner?: MyLearner_learner$key | null
}

function MyLearnerComponent({ learner: key }: MyLearnerComponentProps) {
  const learner = useFragment(Fragment, key)

  if (!learner) {
    return <NotFoundComponent />
  }

  const hideLearningProfile =
    learner.treatments?.find((t) => t.experiment.name === 'HideLearningProfile')
      ?.name === 'HideLearningProfile'

  const noInitiatives =
    learner.initiatives.nodes.filter((initiative) => !initiative.showChoices)
      .length === 0

  // FIXME: Handle Freeport through a treatment?
  const showProfile =
    learner.customer?.name != 'freeport' &&
    !hideLearningProfile &&
    !noInitiatives

  return (
    <>
      <Hero>
        <Breadcrumbs>
          <Link
            to="/lms/my-learners"
            className="duration-150 hover:text-flintOrange"
          >
            My Learners
          </Link>
          <var>{learner.fullName}</var>
        </Breadcrumbs>
        <HeroTitle>
          <var>{learner.fullName}</var>
        </HeroTitle>
      </Hero>

      <MyLearnerSurvey fragmentRef={learner} />

      <InitiativeSelector learnerRef={learner} />

      {showProfile && learner.personalityTest ? (
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 space-y-6">
            <h2 className="text-2xl font-semibold text-flintBlue">
              Learning Profile
            </h2>
            <LearningProfile data={learner} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default function MyLearner() {
  const { myLearnerID } = useParams({
    from: '/lms/my-learners/$myLearnerID',
  })
  const data = useLazyLoadQuery<MyLearnerQuery>(Query, { id: myLearnerID })

  const learner = data.node?.__typename === 'Learner' ? data.node : null

  return (
    <AppContainer page="my-learner" learner={data.learner}>
      <MyLearnerComponent learner={learner} />
    </AppContainer>
  )
}
