/**
 * @generated SignedSource<<b619144d6b6b7eb9b6abc3397b48270c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SelectInitiativeMutation$variables = {
  initiative: string;
};
export type SelectInitiativeMutation$data = {
  readonly chooseInitiative: {
    readonly id: string;
    readonly initiatives: {
      readonly nodes: ReadonlyArray<{
        readonly choices: {
          readonly nodes: ReadonlyArray<{
            readonly id: string;
          }>;
        };
        readonly id: string;
        readonly showChoices: boolean;
      }>;
    };
  };
};
export type SelectInitiativeMutation = {
  response: SelectInitiativeMutation$data;
  variables: SelectInitiativeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "initiative"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "initiative",
        "variableName": "initiative"
      }
    ],
    "concreteType": "Learner",
    "kind": "LinkedField",
    "name": "chooseInitiative",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnerInitiativeConnection",
        "kind": "LinkedField",
        "name": "initiatives",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Initiative",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InitiativeConnection",
                "kind": "LinkedField",
                "name": "choices",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Initiative",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showChoices",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectInitiativeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectInitiativeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "993b6c0102c4a000b9ff20a1bec3d13a",
    "id": null,
    "metadata": {},
    "name": "SelectInitiativeMutation",
    "operationKind": "mutation",
    "text": "mutation SelectInitiativeMutation(\n  $initiative: ID!\n) {\n  chooseInitiative(initiative: $initiative) {\n    id\n    initiatives {\n      nodes {\n        id\n        choices {\n          nodes {\n            id\n          }\n        }\n        showChoices\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fb21a9127f63c74da0199dc2412d7f2";

export default node;
