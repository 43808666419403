import { useEffect, useRef } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import QRCode from 'react-qr-code'
// import { Card, CardContent } from '@/components/Card'

import Activity from '@/components/lms/Activity'
import { PrintActivityQuery } from './__generated__/PrintActivityQuery.graphql'
import { useParams } from '@tanstack/react-router'

const Query = graphql`
  query PrintActivityQuery($assignmentID: ID!) {
    node(id: $assignmentID) {
      ... on Assignment {
        learner {
          locale
        }
        completionDate
        ...ActivityFragment
      }
    }
  }
`

function PrintActivity() {
  const { assignmentID } = useParams({
    from: '/lms/print-activity/$assignmentID',
  })
  const data = useLazyLoadQuery<PrintActivityQuery>(Query, {
    assignmentID,
  })
  window.Localize.setLanguage(data?.node?.learner?.locale || 'en-US')
  const assignment = data?.node
  const effectDone = useRef(false)

  useEffect(() => {
    if (assignment && !effectDone.current) {
      setTimeout(() => {
        window.print()
      }, 1000)
      effectDone.current = true
    }
  }, [assignment])

  if (!assignment) return

  return (
    <>
      <div className="mt-12 w-full space-y-6">
        <Activity assignment={assignment} variant="print" />
        <div className="space-y-2">
          {!assignment.completionDate ? (
            <>
              <h2 className="font-semibold">Complete Activity</h2>

              <QRCode value="https://app.flintls.com/lms/activity?complete=true" />
            </>
          ) : (
            <QRCode value="https://app.flintls.com" />
          )}
        </div>
      </div>
    </>
  )
}

export default PrintActivity
