import Initiative from '@/components/lms/Initiatives/Initiative'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { CurrentInitiativeQuery } from './__generated__/CurrentInitiativeQuery.graphql'
import { InitiativeLearnerFragment$key } from '@/components/lms/Initiatives/__generated__/InitiativeLearnerFragment.graphql'
import { InitiativeEdgeFragment$key } from '@/components/lms/Initiatives/__generated__/InitiativeEdgeFragment.graphql'
import { Navigate } from '@tanstack/react-router'
import { Hero, HeroTitle } from '@/components/lms/Hero'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const Query = graphql`
  query CurrentInitiativeQuery {
    learner {
      ...AppContainer_learner
      ...InitiativeLearnerFragment
      currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
        edges {
          ...InitiativeEdgeFragment
          initiative: node {
            id
            showChoices
          }
        }
      }
    }
  }
`

type CurrentInitiativeComponentProps = {
  learnerRef: InitiativeLearnerFragment$key
  edgeRef: InitiativeEdgeFragment$key
}
export function CurrentInitiativeComponent({
  learnerRef,
  edgeRef,
}: CurrentInitiativeComponentProps) {
  return (
    <>
      <Hero>
        <HeroTitle>Current Initiative</HeroTitle>
      </Hero>
      <Initiative
        edgeRef={edgeRef}
        learnerRef={learnerRef}
        showInteractivity
        learnerIsCurrent
      />
    </>
  )
}

export function CurrentInitiative() {
  const data = useLazyLoadQuery<CurrentInitiativeQuery>(Query, {})
  const learner = data.learner
  const currentInitiative = learner?.currentInitiative?.edges.filter(
    (initiative) => !initiative.initiative.showChoices
  )[0]
  if (!learner || !currentInitiative) return <Navigate to="/lms" />

  return (
    <AppContainer page="current-initiative" learner={learner}>
      <CurrentInitiativeComponent
        learnerRef={learner}
        edgeRef={currentInitiative}
      />
    </AppContainer>
  )
}
