/**
 * @generated SignedSource<<c0122aae74dce37f972ce0680d3e4801>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiativeSelectorLearnerFragment$data = {
  readonly assignments: {
    readonly nodes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"CompletedActivitiesCard_assignments">;
    }>;
  };
  readonly currentInitiative: {
    readonly edges: ReadonlyArray<{
      readonly initiative: {
        readonly id: string;
        readonly showChoices: boolean;
      };
      readonly " $fragmentSpreads": FragmentRefs<"CompletedDialogEdgeFragment" | "FirstTimeDialogEdgeFragment" | "InitiativeEdgeFragment">;
    }>;
  };
  readonly pastInitiatives: {
    readonly edges: ReadonlyArray<{
      readonly assignments: {
        readonly totalCount: number;
      };
      readonly initiative: {
        readonly id: string;
        readonly name: string;
        readonly showChoices: boolean;
      };
      readonly seenEnd: boolean;
      readonly startDate: number;
      readonly " $fragmentSpreads": FragmentRefs<"EndDialogEdgeFragment" | "InitiativeEdgeFragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CompletedDialogLearnerFragment" | "EndDialogLearnerFragment" | "FirstTimeDialogLearnerFragment" | "InitiativeLearnerFragment">;
  readonly " $fragmentType": "InitiativeSelectorLearnerFragment";
};
export type InitiativeSelectorLearnerFragment$key = {
  readonly " $data"?: InitiativeSelectorLearnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiativeSelectorLearnerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "InitiativeEdgeFragment"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showChoices",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiativeSelectorLearnerFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiativeLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndDialogLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompletedDialogLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FirstTimeDialogLearnerFragment"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "feedbackCompleted": true
          }
        }
      ],
      "concreteType": "AssignmentConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CompletedActivitiesCard_assignments"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "assignments(filter:{\"feedbackCompleted\":true})"
    },
    {
      "alias": "currentInitiative",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "atDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FirstTimeDialogEdgeFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CompletedDialogEdgeFragment"
            },
            {
              "alias": "initiative",
              "args": null,
              "concreteType": "Initiative",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"atDate\":0})"
    },
    {
      "alias": "pastInitiatives",
      "args": [
        {
          "kind": "Literal",
          "name": "membershipFilter",
          "value": {
            "beforeDate": 0
          }
        }
      ],
      "concreteType": "LearnerInitiativeConnection",
      "kind": "LinkedField",
      "name": "initiatives",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnerInitiativeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": "initiative",
              "args": null,
              "concreteType": "Initiative",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": "seenEnd",
              "args": [
                {
                  "kind": "Literal",
                  "name": "popupType",
                  "value": "InitiativePopupEnd"
                }
              ],
              "kind": "ScalarField",
              "name": "seenPopup",
              "storageKey": "seenPopup(popupType:\"InitiativePopupEnd\")"
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "filter",
                  "value": {
                    "chosen": true,
                    "feedbackCompleted": true
                  }
                }
              ],
              "concreteType": "AssignmentConnection",
              "kind": "LinkedField",
              "name": "assignments",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": "assignments(filter:{\"chosen\":true,\"feedbackCompleted\":true})"
            },
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EndDialogEdgeFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "initiatives(membershipFilter:{\"beforeDate\":0})"
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "dae4a9e13ea562ff8d46bb22945b91e7";

export default node;
