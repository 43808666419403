import { graphql, useLazyLoadQuery } from 'react-relay'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import Markdown from '@/components/Markdown'

import { AboutQuery } from './__generated__/AboutQuery.graphql'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

const flintContent = `
Training is supposed to help you get better. But that won't happen just by going to classroom workshops and doing eLearning modules. This tool acts as your job coach, serving you a diet of bite-sized, practical activities you can action in the flow of your work.

Even better, your activities are tailored to your role, style, and development needs, based partly on the assessment you completed when you first arrived.

By completing an activity every few days - doing something skillfully in the real world - you'll begin to form the kinds of habits that drive personal, team, and organizational success.
`

const Query = graphql`
  query AboutQuery {
    learner {
      ...AppContainer_learner
      customer {
        aboutPageTitle
        aboutPageContent
        orientationVideoUrl
      }
      locale
    }
  }
`

function About() {
  const data = useLazyLoadQuery<AboutQuery>(Query, {})
  const { aboutPageTitle, aboutPageContent } = data?.learner?.customer || {}
  const haveCustomContent = aboutPageContent && aboutPageTitle

  const videoUrl =
    data?.learner?.customer.orientationVideoUrl ||
    `https://client.flintls.com/2dot0/OTJOrientation_alt'${data?.learner?.locale === 'fr-CA' ? '-FR' : ''}.mp4`

  return (
    <AppContainer page="about" learner={data.learner}>
      {haveCustomContent ? (
        <Card>
          <CardHeader>
            <CardTitle>{aboutPageTitle}</CardTitle>
          </CardHeader>
          <CardContent>
            <Markdown>{aboutPageContent}</Markdown>
          </CardContent>
        </Card>
      ) : null}
      <Card>
        <CardHeader>
          <CardTitle>About Flint&apos;s On-the-Job Activities</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <Markdown>{flintContent}</Markdown>
          <div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              controls
              poster="/video-thumbnail.png"
              controlsList="nodownload"
              className="mx-auto aspect-video w-full rounded-lg border border-slate-950/5 lg:w-3/4"
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </CardContent>
      </Card>
    </AppContainer>
  )
}

export default About
