import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarSeparator,
} from '@/components/Sidebar'
import { Link } from '@tanstack/react-router'
import { graphql, useFragment } from 'react-relay'
import { AppSidebar_learner$key } from './__generated__/AppSidebar_learner.graphql'
import { LogOutIcon } from 'lucide-react'
import NavLink from './NavLink'
import LocaleSelect from './LocaleSelect'

const Fragment = graphql`
  fragment AppSidebar_learner on Learner {
    locale
    ...GeneralSurveyFragment
    completedAssignments: assignments(filter: { completed: true }) {
      totalCount
    }
    currentAssignments: assignments(filter: { completed: false }) {
      totalCount
    }
    readyForAssignments
    personalityTest {
      id
    }
    receivingActivities
    fullName
    localizedLogoUrl
    customer {
      name
    }
    pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
      nodes {
        id
        showChoices
      }
    }
    currentIniatives: initiatives(membershipFilter: { atDate: 0 }) {
      nodes {
        id
        showChoices
      }
    }
    daysOverdueContests {
      nodes {
        contest {
          id
          active
          startDate
        }
      }
    }
    pendingSurvey {
      id
    }
    surveyAwaitingResponse {
      id
    }
    currentPathway {
      pathway {
        visible
      }
    }
    directReports {
      totalCount
    }
    treatments {
      name
      experiment {
        name
      }
    }
    achievementCategories {
      achievements {
        acknowledged
      }
    }
  }
`

export type AppSidebarProps = {
  learner: AppSidebar_learner$key
  highlight?: 'My Learners'
}

function AppSidebar({ learner, highlight }: AppSidebarProps) {
  const data = useFragment(Fragment, learner)

  window.Localize.setLanguage(data.locale)

  const contests = data.daysOverdueContests.nodes
    .map((x) => x.contest)
    .sort((a, b) => a.startDate - b.startDate)
  const activeContest = contests.find((node) => node.active)
  const contestID = activeContest?.id

  const hideLearningProfile =
    data.treatments.find((t) => t.experiment.name === 'HideLearningProfile')
      ?.name === 'HideLearningProfile'

  const showCurrentInitiative =
    data.currentIniatives.nodes.filter((initiative) => !initiative.showChoices)
      .length > 0 && data.receivingActivities

  const showPastInitiatives =
    data.pastInitiatives.nodes.filter((initiative) => !initiative.showChoices)
      .length > 0

  return (
    <Sidebar>
      <SidebarHeader className="pl-4">
        <Link to="/lms" aria-current="false">
          {data?.localizedLogoUrl ? (
            <img
              src={data.localizedLogoUrl}
              alt={data.customer.name}
              className="h-9"
              translate="no"
            />
          ) : (
            <p className="text-2xl font-semibold sm:text-3xl" translate="no">
              {data?.customer.name}
            </p>
          )}
        </Link>
      </SidebarHeader>
      <SidebarSeparator className="my-4" />
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            <NavLink
              to="/lms/activity"
              highlight={[
                '/lms/activity',
                '/lms/general-survey',
                '/lms/waiting',
                '/lms/choose-initiative',
                '/lms/orientation',
              ]}
            >
              Activities
            </NavLink>

            {showCurrentInitiative ? (
              <NavLink
                to="/lms/initiatives/current"
                highlight={['/lms/initiatives/current']}
              >
                Current Initiative
              </NavLink>
            ) : null}
            {showPastInitiatives ? (
              <NavLink
                to="/lms/initiatives/past"
                highlight={['/lms/initiatives/past']}
              >
                Past Initiatives
              </NavLink>
            ) : null}
            {data.directReports.totalCount ? (
              <NavLink
                to="/lms/my-learners"
                highlight={['/lms/my-learners']}
                forceHighlight={
                  highlight ? highlight === 'My Learners' : undefined
                }
              >
                My Learners
              </NavLink>
            ) : null}
            {!data.personalityTest || hideLearningProfile ? null : (
              <NavLink
                to="/lms/learning-profile"
                highlight={['/lms/learning-profile']}
              >
                Learning Profile
              </NavLink>
            )}

            {contestID ? (
              <NavLink
                highlight={['/lms/contests']}
                linkOptions={{
                  to: '/lms/contests/$contestID',
                  params: { contestID },
                }}
              >
                Contests
              </NavLink>
            ) : null}
            {data.achievementCategories.some(
              (x) => x.achievements.length > 0
            ) ? (
              <NavLink to="/lms/achievements" highlight={['/lms/achievements']}>
                Achievements
              </NavLink>
            ) : null}
            <NavLink to="/lms/about" highlight={['/lms/about']}>
              About
            </NavLink>
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="pl-4">
        <span className="font-semibold" translate="no">
          {data?.fullName}
        </span>
        <Link
          to="/lms/logout"
          className="mb-4 flex gap-1.5 text-slate-400 transition-colors hover:text-red-500"
        >
          <span>Logout</span>
          <LogOutIcon className="w-3.5" />
        </Link>
        <LocaleSelect locale={data.locale} />
      </SidebarFooter>
    </Sidebar>
  )
}

export default AppSidebar
