/**
 * @generated SignedSource<<d6efe883b5cf4d2328f38f3689cf718c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AreaQuery$variables = {
  area: string;
};
export type AreaQuery$data = {
  readonly node: {
    readonly __typename: "Area";
    readonly competencies: ReadonlyArray<{
      readonly id: string;
      readonly imageUrl: string | null;
      readonly name: string;
      readonly shortDescription: string;
    }>;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly libraries: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
    readonly longDescription: string;
    readonly name: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AreaQuery = {
  response: AreaQuery$data;
  variables: AreaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "area"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "area"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SkillSet",
  "kind": "LinkedField",
  "name": "competencies",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "LibraryConnection",
  "kind": "LinkedField",
  "name": "libraries",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Library",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AreaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Area",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AreaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Area",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8e9bd4a0bf65904c48cb4d4a3019d8b",
    "id": null,
    "metadata": {},
    "name": "AreaQuery",
    "operationKind": "query",
    "text": "query AreaQuery(\n  $area: ID!\n) {\n  node(id: $area) {\n    __typename\n    ... on Area {\n      id\n      name\n      longDescription\n      imageUrl\n      competencies {\n        id\n        name\n        shortDescription\n        imageUrl\n      }\n      libraries {\n        nodes {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8bbe50a037b63d8c4ebb88aeba11ae6";

export default node;
